import { useState, useEffect, useMemo, useRef, useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Stack, TablePagination } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridPaginationModel,
  GridRowModel,
  GridSortItem,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridApiRef,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid'
import isEqual from 'lodash/isEqual'

import { isPageLoadingVar } from 'graphql/reactiveVariables'
import {
  getColumnVisibility,
  getDataSort,
  getDensityModel,
  getPageNumber,
  getPageSize,
  getPagination,
  removeDataSort,
  removePageNumber,
  removePageSize,
  removePagination,
  setColumnVisibility,
  setDensityModel,
  setPageNumber,
  setPagination,
} from 'helpers'
import useFplDataGridHandlers from 'hooks/fplDataGrid/useFplDataGridHandlers'

import CustomLoadingOverlay from './CustomLoadingOverlay'
import CustomToolbar from './CustomToolbar'
import { getDefaultSortModel, getPaging } from './helpers'
import { IFplDataGridProps } from './interfaces/IFplDataGridProps'

const defaultPageOptions = {
  pageSize: 10,
  rowsPerPage: [5, 10, 100],
}
interface IGridColumn extends Omit<GridColDef, 'sortPath'> {
  sortPath?: Array<string>
}

export interface IPageState {
  page: number
  pageSize: number
}

export interface ICursorPagination {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
}

export interface ISortModel extends GridSortItem {
  orderInput: Record<string, any>
}

const CustomTablePagination = () => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const rowCount = useGridSelector(apiRef, gridRowCountSelector)
  const { loading, pageSizeOptions } = useGridRootProps()

  const handlePageChange = (event, newPage) => {
    if (loading) return
    apiRef.current.setPage(newPage)
  }

  const handlePageSizeChange = (event) => {
    if (loading) return
    const newPageSize = parseInt(event.target.value, 10)
    apiRef.current.setPageSize(newPageSize)
  }

  return (
    <TablePagination
      component='div'
      count={rowCount}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handlePageSizeChange}
      rowsPerPageOptions={pageSizeOptions}
      disabled={loading}
    />
  )
}

const FplDataGrid = (props: IFplDataGridProps) => {
  const {
    query,
    entityName,
    columns,
    queryVariables,
    skipQuery,
    filter,
    pollInterval,
    defaultOrder,
    variableSortOrder,
    rowModesModel,
    isRowSelectable,
    processRowUpdate,
    pageOptions = defaultPageOptions,
    toolbar,
    footer,
    checkboxSelection,
    selectionModel,
    onSelectionModelChange,
    hideToolbar,
    hideFooter,
    // refetchWithDelay,
    showAppBarLoading,
    rootSx,
    getRowHeight,
    onRowClick,
    disableSelectionOnClick,
    fetchPolicy,
    // isHideOldData,
    overwriteId,
    onDataLoadCompleteAction,
    setGridRef,
  } = props

  // Variables
  const id = overwriteId !== undefined ? overwriteId : entityName

  const densityModel = getDensityModel(id)

  const pageNumber = getPageNumber(id)

  const cursorPagination = getPagination(id)

  const dataSort = getDataSort(id)

  const pageSize = getPageSize(id)

  const visibilityModel = getColumnVisibility(id)

  const gridApiRef = useGridApiRef()

  if (gridApiRef.current && setGridRef) {
    setGridRef(gridApiRef)
  }

  // States
  const [density, setDensity] = useState<GridDensity>(densityModel)

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(visibilityModel)

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: pageNumber ?? 0,
    pageSize: pageSize ?? defaultPageOptions.pageSize,
  })

  const prevFilters = useRef<any>(filter)

  const [sortModel, setSortModel] = useState<Array<ISortModel>>(() =>
    getDefaultSortModel(dataSort ?? defaultOrder, columns),
  )

  const [cursorPaginationModel, setCursorPagination] = useState<ICursorPagination>(
    getPaging(cursorPagination ?? { first: pageSize ?? defaultPageOptions.pageSize }),
  )

  // Query
  const { data, loading, fetchMore } = useQuery(query, {
    fetchPolicy: fetchPolicy,
    variables: {
      ...cursorPaginationModel,
      order:
        sortModel[0]?.orderInput ?? getDefaultSortModel(variableSortOrder, columns)[0]?.orderInput,
      where: filter,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    pollInterval: pollInterval,
  })

  const rowCount = data?.[entityName]?.totalCount ?? 0

  const rows: Array<GridRowModel> = useMemo(() => {
    if (data?.[entityName]?.edges) {
      const dataArray = data[entityName].edges.map((row: any) => row.node)

      return dataArray
    } else {
      return []
    }
  }, [data, entityName])

  const resetPagination = useCallback(() => {
    setPageNumber(0, id)

    setPagination(getPaging({ first: getPageSize(id) }), id)

    setPaginationModel((prev) => ({ page: 0, pageSize: prev.pageSize }))

    setCursorPagination((prev) => getPaging({ first: prev.first }))
  }, [id])

  // Effects
  useEffect(() => {
    const handleBeforeUnload = () => {
      removePageNumber(id)
      removeDataSort(id)
      removePagination(id)
      removePageSize(id)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [id])

  useEffect(() => {
    const queryFetch = async () => {
      if (skipQuery) {
        return
      }

      await fetchMore({
        variables: {
          ...cursorPaginationModel,
          order:
            sortModel[0]?.orderInput ??
            getDefaultSortModel(variableSortOrder, columns)[0]?.orderInput,
          where: filter,
          ...queryVariables,
        },
      })
    }

    queryFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorPaginationModel, sortModel, queryVariables, skipQuery, fetchMore])

  useEffect(() => {
    if (!isEqual(filter, prevFilters.current)) {
      prevFilters.current = filter
      resetPagination()
    }
  }, [filter, resetPagination])

  useEffect(() => {
    if (!loading && data && onDataLoadCompleteAction) {
      onDataLoadCompleteAction(data, loading)
    }
  }, [data, loading, onDataLoadCompleteAction])

  useEffect(() => {
    if (showAppBarLoading) {
      isPageLoadingVar(loading)
    }
  }, [loading, showAppBarLoading])

  // Handlers
  const { handleRowClick, handleSortModelChange, handleRefreshClick, handlePageChange } =
    useFplDataGridHandlers({
      onRowClick,
      data,
      entityName,
      setCursorPagination,
      setPaginationModel,
      id,
      setSortModel,
      columns,
      paginationModel,
    })

  return (
    <Box sx={{ width: 1, ...rootSx }} display={'flex'} flexDirection={'column'} maxHeight={800}>
      <DataGrid
        autoHeight={!rows.length}
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        loading={loading}
        components={{
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              No results found.
            </Stack>
          ),
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: !hideToolbar ? CustomToolbar : undefined,
          Footer: footer ? footer.component : undefined,
          Pagination: CustomTablePagination,
        }}
        componentsProps={{
          toolbar: {
            caption: toolbar?.caption,
            refreshButton: toolbar?.refreshButton,
            leftSide: toolbar?.leftSide,
            rightSide: toolbar?.rightSide,
            onRefreshClick: handleRefreshClick,
          },
          ...(footer && {
            footer: { ...footer.componentProps },
          }),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibility(newModel, id)
          setColumnVisibilityModel(newModel)
        }}
        onStateChange={(v) => {
          setDensity(v.density.value)
          setDensityModel(v.density.value, id)
        }}
        density={density}
        onRowClick={handleRowClick}
        getRowHeight={getRowHeight}
        filterMode='server'
        disableColumnFilter
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: pageOptions.pageSize,
            },
          },
        }}
        disableRowSelectionOnClick={disableSelectionOnClick}
        paginationMode='server'
        pageSizeOptions={pageOptions.rowsPerPage}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePageChange}
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        isRowSelectable={isRowSelectable}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionModelChange}
        editMode='row'
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        hideFooter={hideFooter}
      />
    </Box>
  )
}

export { FplDataGrid }
export type { IGridColumn }
