import { useState } from 'react'

import { AppBar, Box, Paper, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

import { TabPanel } from 'components/common'
import { AccountPayableStatusCategories } from 'constants/AccountPayableStatusCategories'
import { accountPayableStatusCategoryVar } from 'graphql/reactiveVariables'
import { useHashToSelectTab } from 'helpers'

import AccountPayableFilter from './AccountPayableFilter/AccountPayableFilter'
import InvoiceBatches from './AccountPayableFilter/InvoiceBatches'
import AccountsPayable from './AccountsPayable'
import OverviewTab from './Overview/OverviewTab'

export enum TabId {
  Overview = 0,
  All = 1,
  UnderReview = 2,
  Queries = 3,
  Flagged = 4,
  ScheduledPayment = 5,
  PaymentSent = 6,
  PaymentFailed = 7,
  Archive = 8,
  InvoiceBatches = 9,
}

export const tabsList = [
  {
    id: TabId.Overview,
    label: 'Overview',
    hash: '#accountsPayable-overview',
  },
  {
    id: TabId.All,
    label: 'All',
    hash: '#accountsPayable-all',
  },
  {
    id: TabId.UnderReview,
    label: 'Under Review',
    hash: '#accountsPayable-under-review',
  },
  {
    id: TabId.Queries,
    label: 'Queries',
    hash: '#accountsPayable-queries',
  },
  {
    id: TabId.Flagged,
    label: 'Flagged',
    hash: '#accountsPayable-flagged',
  },
  {
    id: TabId.ScheduledPayment,
    label: 'Scheduled Payment',
    hash: '#accountsPayable-scheduled-payment',
  },
  {
    id: TabId.PaymentSent,
    label: 'Payment Sent',
    hash: '#accountsPayable-payment-sent',
  },
  {
    id: TabId.PaymentFailed,
    label: 'Payment Failed',
    hash: '#accountsPayable-payment-failed',
  },
  {
    id: TabId.Archive,
    label: 'Archived',
    hash: '#accountsPayable-archived',
  },
  {
    id: TabId.InvoiceBatches,
    label: 'Invoice Batches',
    hash: '#accountsPayable-invoiceBatches',
  },
]
function commonProps(index: any) {
  return {
    id: `dashboard-tab-${index}`,
    'aria-controls': `dashboard-tabpanel-${index}`,
  }
}
const AccountsPayableTabs = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  useHashToSelectTab(tabsList, setTabValue)
  const renderTabs = () =>
    tabsList.map((tab) => (
      <Tab
        label={tab.label}
        key={tab.id}
        {...commonProps(tab.id)}
        component={Link}
        to={{ hash: tab.hash }}
        onClick={() => {
          localStorage.removeItem(`${tab.hash.split('#')[1]}_groupBy`)
          switch (tab.label) {
            case 'All':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.All)
              break

            case 'Under Review':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.UnderReview)
              break

            case 'Queries':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.OpenQueries)
              break

            case 'Flagged':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.Flagged)
              break

            case 'Scheduled Payment':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.ScheduledPayment)
              break

            case 'Payment Sent':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.PaymentSent)
              break

            case 'Payment Failed':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.PaymentFailed)
              break

            case 'Archived':
              accountPayableStatusCategoryVar(AccountPayableStatusCategories.Archived)
              break
          }
        }}
      />
    ))
  return (
    <div>
      <Box mb={2}>
        <Paper elevation={3}>
          <AccountPayableFilter />
        </Paper>
      </Box>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='accounts payable tabs'
          variant='scrollable'
          TabIndicatorProps={{ style: { transition: 'none' } }}>
          {renderTabs()}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={TabId.Overview}>
        <OverviewTab></OverviewTab>
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.All}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.UnderReview}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.Queries}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.Flagged}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.ScheduledPayment}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.PaymentSent}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.PaymentFailed}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.Archive}>
        <AccountsPayable />
      </TabPanel>
      <TabPanel value={tabValue} index={TabId.InvoiceBatches}>
        <InvoiceBatches />
      </TabPanel>
    </div>
  )
}
export default AccountsPayableTabs
