import { useState, useMemo, useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import {
  ControlledCheckbox,
  ControlledTextField,
  FplDataGrid,
  IGridColumn,
} from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { ContactFilterInput, SortEnumType, useDeleteContactMutation } from 'generated/graphql'
import { GET_CUSTOMER_CONTACTS, GET_PAGED_CUSTOMER_CONTACTS } from 'graphql/queries'

import { CreateUpdateContact } from './createUpdateContact'
import { usePermission } from 'providers'

interface IProps {
  customerId: number | null
}

const Contacts = (props: IProps) => {
  const { customerId } = props
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null)
  const [openContactDialog, setOpenContactDialog] = useState(false)

  const [isAllowedToDeleteCustomerContact] = usePermission('DeleteCustomerContact')

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '', isActive: true },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''
  const searchActive = watch('isActive', true)

  const [deleteContact, { loading: deleteContactProcessing }] = useDeleteContactMutation({
    refetchQueries: [
      {
        query: GET_CUSTOMER_CONTACTS,
        variables: {
          customerId,
          order: { id: SortEnumType.Asc },
        },
      },
    ],
    onCompleted(data) {
      if (data.deleteContact) {
        toast.success(`Contact was deleted with success!`)
      }
    },
  })

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedContactId(Number(id))
    setOpenContactDialog(true)
  }

  const handleCreateContact = () => {
    setSelectedContactId(null)
    setOpenContactDialog(true)
  }

  const handleDeleteContact = useCallback(
    (id: GridRowId) => () => {
      deleteContact({
        variables: {
          contactId: Number(id),
        },
      })
    },
    [deleteContact],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'telephoneNumber', headerName: 'Tel No', minWidth: 200, flex: 0.7 },
      { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.7 },
      { field: 'additionalEmails', headerName: 'Additional Email(s)', minWidth: 200, flex: 0.7 },
      { field: 'isDefault', headerName: 'Default', minWidth: 150, flex: 0.7, type: 'boolean' },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deleteContactProcessing || !isAllowedToDeleteCustomerContact}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteContact(id)}
          />,
        ],
      },
    ],
    [deleteContactProcessing, handleDeleteContact, isAllowedToDeleteCustomerContact],
  )

  const filter: ContactFilterInput | null = useMemo(() => {
    return {
      or: [{ name: { contains: searchValue } }],
      and: [{ isActive: { eq: searchActive } }],
    }
  }, [searchValue, searchActive])

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_CUSTOMER_CONTACTS}
        queryVariables={{ customerId }}
        entityName='pagedCustomerContacts'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Contacts',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateContact}>
                New Contact
              </Button>
            </Grid>
          ),
          rightSide: (
            <>
              <Grid item>
                <ControlledCheckbox
                  control={control}
                  name='isActive'
                  label='Is Active'
                  size='small'
                  defaultValue={true}
                />
              </Grid>
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            </>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openContactDialog && (
        <CreateUpdateContact
          customerId={customerId}
          selectedContactId={selectedContactId}
          openDialog={openContactDialog}
          handleCloseDialog={handleCloseContactDialog}
        />
      )}
    </>
  )
}

export default Contacts
