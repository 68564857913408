import { useEffect, useMemo, useState } from 'react'

import { Box, Button, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { GridCellParams, GridRowModel, GridSortItem } from '@mui/x-data-grid'
import clsx from 'clsx'
import { format, parseJSON, subHours } from 'date-fns'
import GoogleMapReact from 'google-map-react'

import DriverIcon from 'components/bookings/MapRoute/DriverIcon'
import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT } from 'constants/index'
import {
  CurrentDriversLocationsGridQuery,
  DriverLocationFilterInput,
  useGetDriverNextRouteLazyQuery,
} from 'generated/graphql'
import { GET_DRIVERS_CURRENT_LOCATION_DATA } from 'graphql/queries'

interface IDriverRoute {
  id: string
  longitude: string
  latitude: string
  placeId: string | null | undefined
}

const BRISTOL_COORDINATES = { lat: 51.45, lng: -2.59 }
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''

const columns: IGridColumn[] = [
  {
    field: 'driverId',
    headerName: 'Driver ID',
    sortPath: ['driverId'],
    flex: 0.1,
  },
  {
    field: 'driverName',
    headerName: 'Driver Name',
    sortPath: ['driver', 'name'],
    flex: 0.2,
    valueGetter(params) {
      return params.row.driver.name
    },
  },
  {
    field: 'lastTrackedAt',
    headerName: 'Last Tracked At',
    sortable: false,
    flex: 0.2,
    valueGetter(params) {
      const date = params.row.lastModifiedAt ?? params.row.createdAt
      return date ? format(parseJSON(date as string), DATE_TIME_FORMAT) : ''
    },
  },
  {
    field: 'allocated',
    headerName: 'Allocated',
    sortable: false,
    flex: 0.1,
    valueGetter(params) {
      return params.row.driverTrackingStatus.allocated
    },
  },
  {
    field: 'nextStopPostCode',
    headerName: 'Next Stop Post Code',
    sortable: false,
    flex: 0.2,
    valueGetter(params) {
      return params.row.driverTrackingStatus.nextStopPostCode
    },
  },
  {
    field: 'nextStopDateTime',
    headerName: 'Next Stop Date Time',
    sortable: false,
    flex: 0.2,
    valueGetter(params) {
      const date = params.row.driverTrackingStatus.nextStopDateTime
      return date ? format(parseJSON(date as string), DATE_TIME_FORMAT) : 'N/A'
    },
  },
  {
    field: 'nextStopLiveEta',
    headerName: 'Next Stop Live ETA',
    cellClassName: (params: GridCellParams<GridRowModel>) =>
      clsx({
        onTime:
          params.row.driverTrackingStatus.nextStopLiveEta &&
          params.row.driverTrackingStatus.nextStopLiveEta <=
            params.row.driverTrackingStatus.nextStopDateTime,
        late:
          params.row.driverTrackingStatus.nextStopLiveEta &&
          params.row.driverTrackingStatus.nextStopLiveEta >
            params.row.driverTrackingStatus.nextStopDateTime,
      }),
    sortable: false,
    flex: 0.2,
    valueGetter(params) {
      const date = params.row.driverTrackingStatus.nextStopLiveEta
      return date ? format(parseJSON(date), DATE_TIME_FORMAT) : 'N/A'
    },
  },
]

function DriversTracking() {
  const [driverRoute, setDriverRoute] = useState<IDriverRoute>()
  const [selectedDriverId, setSelectedDriverId] = useState(-1)
  const [loading, setLoading] = useState(true)
  const [defaultMapCenter, setDefaultMapCenter] = useState(BRISTOL_COORDINATES)
  const [gridDrivers, setDrivers] = useState<CurrentDriversLocationsGridQuery | undefined>()

  const [getDriverNextRoute, { data: getDriverNextRouteData }] = useGetDriverNextRouteLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (getDriverNextRouteData) {
      const defaultItem: IDriverRoute = {
        id: getDriverNextRouteData.driverNextRoute.id,
        placeId: getDriverNextRouteData.driverNextRoute.nextStopGoogleMapsPlaceId,
        latitude: getDriverNextRouteData.driverNextRoute.latitude,
        longitude: getDriverNextRouteData.driverNextRoute.longitude,
      }

      setDriverRoute(defaultItem)
      setDefaultMapCenter({
        lat: Number(getDriverNextRouteData.driverNextRoute.latitude),
        lng: Number(getDriverNextRouteData.driverNextRoute.longitude),
      })
    }
  }, [getDriverNextRouteData, selectedDriverId])

  const handleApiLoaded = (map, maps) => {
    if (!driverRoute || selectedDriverId === -1) return
    const directionsService = new maps.DirectionsService()
    const directionsRenderer = new maps.DirectionsRenderer()
    directionsRenderer.setMap(map)
    let origin: string | null | undefined = ''
    let destination: { placeId: string | null | undefined } = { placeId: '' }
    const waypoints = [] as any

    origin = driverRoute?.latitude + ',' + driverRoute?.longitude
    destination = { placeId: driverRoute.placeId }

    if (!destination.placeId || destination.placeId === '') return
    const request = {
      origin,
      destination,
      waypoints,
      optimizeWaypoints: false,
      provideRouteAlternatives: true,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: new Date(),
      },
      unitSystem: maps.UnitSystem.IMPERIAL,
    }
    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response)
      }
    })
  }

  const handleRowClick = (row) => {
    getDriverNextRoute({
      variables: { driverId: row.row.driverId },
      onCompleted: () => setSelectedDriverId(row.row.driverId),
    })
  }

  const onDataLoadCompleteAction = (loadedDrivers, loading) => {
    setDrivers(loadedDrivers)
    setLoading(loading)
  }

  const onResetButtonClick = () => {
    setSelectedDriverId(-1)
  }

  const DefaultOrder: GridSortItem = {
    field: 'id',
    sort: 'asc',
  }

  const filter: DriverLocationFilterInput = useMemo(() => {
    const currentDate = new Date()
    const yesterday = subHours(currentDate, 24)

    return {
      and: [
        { driverId: { neq: null } },
        {
          or: [
            { and: [{ lastModifiedAt: { neq: null } }, { lastModifiedAt: { gte: yesterday } }] },
            { createdAt: { gte: yesterday } },
          ],
        },
      ],
    }
  }, [])

  const getDriverPinsLocations = () => {
    if (selectedDriverId === -1) {
      return gridDrivers?.currentDriversLocations?.edges?.map((l) => (
        <DriverIcon
          key={l.node.driverId}
          lat={Number(l.node.latitude)}
          lng={Number(l.node.longitude)}
        />
      ))
    }
    const driverLocation = gridDrivers?.currentDriversLocations?.edges?.find(
      (driver) => driver.node.driverId === selectedDriverId,
    )?.node
    return (
      <DriverIcon
        key={driverLocation?.driverId}
        lat={Number(driverRoute?.latitude)}
        lng={Number(driverRoute?.longitude)}
      />
    )
  }

  return (
    <Grid container spacing={3} columns={8}>
      <Grid item xs={8} lg={4}>
        <Paper variant='outlined'>
          <FplDataGrid
            query={GET_DRIVERS_CURRENT_LOCATION_DATA}
            isHideOldData
            refetchWithDelay
            entityName='currentDriversLocations'
            variableSortOrder={DefaultOrder}
            toolbar={{
              caption: 'Drivers Tracking',
            }}
            onRowClick={handleRowClick}
            columns={columns}
            filter={filter}
            onDataLoadCompleteAction={onDataLoadCompleteAction}
            rootSx={{
              '& .onTime': { backgroundColor: green[500] },
              '& .late': { backgroundColor: red[500] },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={8} lg={4}>
        <Paper variant='outlined'>
          <Stack direction='row' justifyContent='space-between' padding={2}>
            <Typography variant='h6'>Live Tracking</Typography>
            <Button onClick={onResetButtonClick}>Reset</Button>
          </Stack>
          <Box height={{ xs: 500, lg: 730 }}>
            {!loading ? (
              <GoogleMapReact
                key={selectedDriverId}
                bootstrapURLKeys={{ key: API_KEY }}
                defaultCenter={defaultMapCenter}
                defaultZoom={5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                {getDriverPinsLocations()}
              </GoogleMapReact>
            ) : (
              <Skeleton />
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DriversTracking
