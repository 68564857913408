import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, FplDialogTitle, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY } from 'constants/index'
import { TariffPagedDtoFilterInput } from 'generated/graphql'
import { GET_USER_PAGED_TARIFFS } from 'graphql/queries'
import { useMemo } from 'react'
import { resetPagination } from 'helpers'

interface IGridColumnExtended extends IGridColumn {
  hide?: boolean
}

const columns: IGridColumnExtended[] = [
  {
    field: 'tariffIcon',
    headerName: 'Icon',
    width: 200,
    sortable: false,
    renderCell: ({ value }) => {
      return (
        <img src={value?.file?.absoluteUri} alt={`${value?.name} Icon`} style={{ maxHeight: 60 }} />
      )
    },
  },
  { field: 'name', headerName: 'Type of Service', minWidth: 100, flex: 0.5 },
  {
    field: 'vehicleCapacity',
    headerName: 'Vehicle Capacity',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box py={1} whiteSpace='pre-line'>
        {row?.tariffIcon?.vehicleCapacity}
      </Box>
    ),
  },
  { field: 'visibilitySequenceNumber', headerName: 'Sequence Number', hide: true },
]

interface IProps {
  open: boolean
  setOpen: any
  onSelectTariff: (id: number) => void
}

const SelectExternalTariffDialog = (props: IProps) => {
  const entityName = 'userPagedTariffs'
  const { open, setOpen, onSelectTariff } = props

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleClose = () => {
    setOpen(false)
    resetPagination(entityName)
  }

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectTariff(Number(id))
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: TariffPagedDtoFilterInput = useMemo(() => {
    return {
      or: [{ ...(searchValue && { name: { contains: searchValue } }), isExternal: { eq: true } }],
    }
  }, [searchValue])

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='select-tariff-dialog'>
      <FplDialogTitle id='select-tariff-dialog' onClose={handleClose}>
        Select Tariff
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_USER_PAGED_TARIFFS}
          entityName={entityName}
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'visibilitySequenceNumber', sort: 'asc' }}
          toolbar={{
            caption: 'Tariff List',
            rightSide: (
              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            ),
          }}
          getRowHeight={() => 'auto'}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectExternalTariffDialog
