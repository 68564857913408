import { useMemo } from 'react'

import { useReactiveVar } from '@apollo/client'

import {
  allStatusesFilter,
  approvedStatusFilter,
  archivedStatusFilter,
  awaitingApprovalStatusFilter,
  failedPaymentStatusFilter,
  openQueriesStatusFilter,
  paymentSentStatusFilter,
  scheduledPaymentStatusFilter,
} from 'components/accountsPayable/AccountPayableFilter/AccountPayableQueryFilters'
import { AccountPayableStatusCategories } from 'constants/AccountPayableStatusCategories'
import {
  AccountPayableRecordFilterInput,
  AccountsPayableFilterCriteria,
  GroupedAccountPayableRecordDtoFilterInput,
} from 'generated/graphql'
import {
  accountPayableFiltersFormVar,
  accountPayableStatusCategoryVar,
} from 'graphql/reactiveVariables'
import { dateTimeZoneFormat } from 'helpers'

interface IProps {
  supplierFilterId: number
}

const xeroPurchaseCodesResult = (accountPayableFilters: any) => {
  let xeroPurchaseCodes = accountPayableFilters.xeroPurchaseCodes?.map((x) => x.value)

  if (xeroPurchaseCodes?.some((item) => item === null)) {
    xeroPurchaseCodes = [...xeroPurchaseCodes, '']
  }

  return xeroPurchaseCodes
}

function useGetAccountsPayableFilters(props: IProps) {
  const { supplierFilterId } = props

  const accountPayableStatusCategory = useReactiveVar(accountPayableStatusCategoryVar)
  const accountPayableFilters = useReactiveVar(accountPayableFiltersFormVar)

  const ungroupedTableFilter: AccountPayableRecordFilterInput = useMemo(() => {
    let statusCategoryFilterInput
    switch (accountPayableStatusCategory) {
      case AccountPayableStatusCategories.All:
        statusCategoryFilterInput = allStatusesFilter
        break

      case AccountPayableStatusCategories.UnderReview:
        statusCategoryFilterInput = awaitingApprovalStatusFilter
        break

      case AccountPayableStatusCategories.Flagged:
        statusCategoryFilterInput = approvedStatusFilter
        break

      case AccountPayableStatusCategories.PaymentSent:
        statusCategoryFilterInput = paymentSentStatusFilter
        break

      case AccountPayableStatusCategories.PaymentFailed:
        statusCategoryFilterInput = failedPaymentStatusFilter
        break

      case AccountPayableStatusCategories.Archived:
        statusCategoryFilterInput = archivedStatusFilter
        break

      case AccountPayableStatusCategories.ScheduledPayment:
        statusCategoryFilterInput = scheduledPaymentStatusFilter
        break

      case AccountPayableStatusCategories.OpenQueries:
        statusCategoryFilterInput = openQueriesStatusFilter
        break
    }
    const supplierFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: { bookingDriver: { driver: { id: { eq: supplierFilterId } } } },
    }

    const searchDriverNameFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: {
        bookingDriver: { driver: { name: { contains: accountPayableFilters.driverName } } },
      },
    }

    const searchDriverNumberFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: {
        bookingDriver: {
          driver: { telephoneNumber: { contains: accountPayableFilters.driverNumber } },
        },
      },
    }

    const xeroPurchaseCodes = xeroPurchaseCodesResult(accountPayableFilters)

    const searchXeroPurchaseCodeFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: {
        bookingDriver: {
          driver: {
            xeroPurchaseCode: { in: xeroPurchaseCodes },
          },
        },
      },
    }

    const searchCourierExchangeLoadIdFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: {
        booking: { courierExchangeLoadId: { eq: accountPayableFilters.courierExchangeLoadId } },
      },
    }

    const searchOurReferenceFilter: AccountPayableRecordFilterInput = {
      bookingInvoice: {
        booking: { id: { eq: Number(accountPayableFilters.ourReference?.match(/\d+/)[0]) } },
      },
    }

    const searchNetAmountFilter: AccountPayableRecordFilterInput = {
      net: { eq: Number(accountPayableFilters.netInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      currency: { symbol: { eq: accountPayableFilters.netInvoiceAmount?.match(/\p{Sc}/gu)[0] } },
    }

    const searchVatAmountFilter: AccountPayableRecordFilterInput = {
      vat: { eq: Number(accountPayableFilters.vatInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      currency: { symbol: { eq: accountPayableFilters.vatInvoiceAmount?.match(/\p{Sc}/gu)[0] } },
    }

    const searchGrossAmountFilter: AccountPayableRecordFilterInput = {
      gross: { eq: Number(accountPayableFilters.grossInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      currency: { symbol: { eq: accountPayableFilters.grossInvoiceAmount?.match(/\p{Sc}/gu)[0] } },
    }

    const newDateStart = dateTimeZoneFormat(accountPayableFilters.dueInStartDate, 'start')
    const newDateEnd = dateTimeZoneFormat(accountPayableFilters.dueInEndDate, 'end')

    const searchDueInStartDateFilter: AccountPayableRecordFilterInput = {}
    const searchDueInEndDateFilter: AccountPayableRecordFilterInput = {}

    const { dateSelection } = accountPayableFilters

    if (dateSelection === AccountsPayableFilterCriteria.InvoiceDate) {
      searchDueInStartDateFilter.bookingInvoice = { invoiceDate: { gte: newDateStart } }
      searchDueInEndDateFilter.bookingInvoice = { invoiceDate: { lte: newDateEnd } }
    } else if (dateSelection === AccountsPayableFilterCriteria.PaymentDate) {
      searchDueInStartDateFilter.bookingInvoice = { paymentDate: { gte: newDateStart } }
      searchDueInEndDateFilter.bookingInvoice = { paymentDate: { lte: newDateEnd } }
    }

    const searchFilter: AccountPayableRecordFilterInput = {
      and: [
        accountPayableFilters.dueInStartDate ? searchDueInStartDateFilter : {},
        accountPayableFilters.dueInEndDate ? searchDueInEndDateFilter : {},
        xeroPurchaseCodes?.length > 0 ? searchXeroPurchaseCodeFilter : {},
      ],
      or: [
        accountPayableFilters.driverName ? searchDriverNameFilter : {},
        accountPayableFilters.driverNumber ? searchDriverNumberFilter : {},
        accountPayableFilters.courierExchangeLoadId ? searchCourierExchangeLoadIdFilter : {},
        accountPayableFilters.ourReference ? searchOurReferenceFilter : {},
        accountPayableFilters.netInvoiceAmount ? searchNetAmountFilter : {},
        accountPayableFilters.vatInvoiceAmount ? searchVatAmountFilter : {},
        accountPayableFilters.grossInvoiceAmount ? searchGrossAmountFilter : {},
      ],
    }

    return {
      and: [statusCategoryFilterInput, supplierFilterId !== -1 ? supplierFilter : {}, searchFilter],
    }
  }, [accountPayableStatusCategory, supplierFilterId, accountPayableFilters])

  const groupedTableFilter: GroupedAccountPayableRecordDtoFilterInput = useMemo(() => {
    const supplierFilter: GroupedAccountPayableRecordDtoFilterInput = {
      driverId: { eq: supplierFilterId },
    }

    const searchDriverNameFilter: GroupedAccountPayableRecordDtoFilterInput = {
      driverName: { contains: accountPayableFilters.driverName },
    }

    const searchDriverNumberFilter: GroupedAccountPayableRecordDtoFilterInput = {
      driverNumber: { contains: accountPayableFilters.driverNumber },
    }

    const xeroPurchaseCodes = xeroPurchaseCodesResult(accountPayableFilters)

    const searchXeroPurchaseCodeFilter: GroupedAccountPayableRecordDtoFilterInput = {
      xeroPurchaseCode: { in: xeroPurchaseCodes },
    }

    const searchCourierExchangeLoadIdFilter: GroupedAccountPayableRecordDtoFilterInput = {
      courierExchangeLoadId: { eq: accountPayableFilters.courierExchangeLoadId },
    }

    const searchOurReferenceFilter: GroupedAccountPayableRecordDtoFilterInput = {
      ourReference: { eq: Number(accountPayableFilters.ourReference?.match(/\d+/)[0]) },
    }

    const searchNetAmountFilter: GroupedAccountPayableRecordDtoFilterInput = {
      netAmounts: {
        some: { eq: Number(accountPayableFilters.netInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      },
      currencySymbols: {
        some: { eq: accountPayableFilters.netInvoiceAmount?.match(/\p{Sc}/gu)[0] },
      },
    }

    const searchVatAmountFilter: GroupedAccountPayableRecordDtoFilterInput = {
      vatAmounts: {
        some: { eq: Number(accountPayableFilters.vatInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      },
      currencySymbols: {
        some: { eq: accountPayableFilters.vatInvoiceAmount?.match(/\p{Sc}/gu)[0] },
      },
    }

    const searchGrossAmountFilter: GroupedAccountPayableRecordDtoFilterInput = {
      grossAmounts: {
        some: { eq: Number(accountPayableFilters.grossInvoiceAmount?.match(/\d+(\.\d+)?/)[0]) },
      },
      currencySymbols: {
        some: { eq: accountPayableFilters.grossInvoiceAmount?.match(/\p{Sc}/gu)[0] },
      },
    }

    const searchFilter: GroupedAccountPayableRecordDtoFilterInput = {
      // and: [
      //   searchDueInStartDateFilter,
      // ],
      or: [
        accountPayableFilters.driverName ? searchDriverNameFilter : {},
        accountPayableFilters.driverNumber ? searchDriverNumberFilter : {},
        accountPayableFilters.courierExchangeLoadId ? searchCourierExchangeLoadIdFilter : {},
        accountPayableFilters.ourReference ? searchOurReferenceFilter : {},
        xeroPurchaseCodes?.length > 0 ? searchXeroPurchaseCodeFilter : {},
        accountPayableFilters.netInvoiceAmount ? searchNetAmountFilter : {},
        accountPayableFilters.vatInvoiceAmount ? searchVatAmountFilter : {},
        accountPayableFilters.grossInvoiceAmount ? searchGrossAmountFilter : {},
      ],
    }

    return {
      and: [supplierFilterId !== -1 ? supplierFilter : {}, searchFilter],
    }
  }, [supplierFilterId, accountPayableFilters])

  return [ungroupedTableFilter, groupedTableFilter]
}

export default useGetAccountsPayableFilters
