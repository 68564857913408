import { AccountPayableRecordStatus } from 'generated/graphql'
import { TOrderDetails } from 'types/orderDetails.types'
import OrderDetailsItem from './OrderDetailsItem'
import React from 'react'

interface IProps {
  orderDetails: TOrderDetails
  OrderDetailsEditForm: React.ReactNode
}

export const OrderDetailsLabel = {
  fplRef: 'Our Ref',
  customerRef: 'Customer Ref',
  loadId: 'Load ID',
  transactionId: 'Transaction ID',
  //agreedRate: 'TODO',
  totalPrice: 'Total Price',
  tariff: 'Tariff',
  tariffCategory: 'Tariff Category',
}

function OrderDetailsItems({ orderDetails, OrderDetailsEditForm }: IProps) {
  return (
    <div>
      {Object.keys(OrderDetailsLabel).map((key) => (
        <React.Fragment key={key}>
          <OrderDetailsItem key={key} label={OrderDetailsLabel[key]} value={orderDetails[key]} />
          {key === 'loadId' && OrderDetailsEditForm}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OrderDetailsItems
