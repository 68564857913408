import { useMemo, useState } from 'react'

import { Button, Grid } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { FplDataGrid, IGridColumn } from 'components/common'
import { DATE_TIME_FORMAT, Paths } from 'constants/index'
import { FileCategory, FileFilterInput, useGetPagedFilesLazyQuery } from 'generated/graphql'
import { GET_PAGED_FILES_QUERY } from 'graphql/queries'

import { UploadExternalInvoice } from './UploadExternalInvoice'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'originalFileName', headerName: 'File Name', minWidth: 150, flex: 2 },
  { field: 'category', headerName: 'Category', minWidth: 100, flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => format(parseJSON(value as string), DATE_TIME_FORMAT),
  },
  { field: 'createdBy', headerName: 'Created By', minWidth: 100, flex: 1 },
]

const ExternalInvoiceTable = () => {
  const { push } = useHistory()
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false)

  const filter: FileFilterInput = useMemo(() => {
    return {
      category: { eq: FileCategory.ExternalDriverInvoice },
    }
  }, [])

  // queries
  const [fetchFiles] = useGetPagedFilesLazyQuery({
    fetchPolicy: 'network-only',
  })

  // handlers
  const handleAddNewClick = () => setInvoiceDialogOpen(true)

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    push(Paths.reports.matchExternalInvoices(id))
  }

  const handleCloseDialog = () => {
    setInvoiceDialogOpen(false)

    fetchFiles({
      variables: { where: filter },
    })
  }

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_FILES_QUERY}
        entityName='files'
        columns={columns}
        defaultOrder={{ field: 'createdAt', sort: 'desc' }}
        toolbar={{
          caption: 'External Invoices',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddNewClick}>
                Add New
              </Button>
            </Grid>
          ),
        }}
        filter={filter}
        onRowClick={handleRowClick}
      />

      <UploadExternalInvoice onCloseDialog={handleCloseDialog} open={invoiceDialogOpen} />
    </>
  )
}

export default ExternalInvoiceTable
