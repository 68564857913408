export { Mode } from './Mode'
export { Paths, PARAM_NEW, PARAM_ID } from './Paths'
export { default as FileConstants } from './FileConstants'
export * from './ValidationMessages'
export * from './EntityConstants'
export * from './StatusCategories'
export * from './Table'
export * from './Theme'
export * from './env.constants'
export * from './RegExConstants'
export * from './ErrorCodes'
export * from './TransitTime'
export * from './JoiValidationOptions'
export * from './DateFormats'
export * from './Packaging'
export * from './AccountPayableStatusCategories'
export * from './Tabs'

export const DEBOUNCE_DELAY = 500
export const DEFAULT_COUNTRY_ID = 1
export const NON_VAT_REGISTERED_CATEGORY_ID = '1'
export const DEFAULT_VAT_CATEGORY_ID = '2'

export const SESSION_STORAGE_OIDC_KEY = 'oidc_login.default'

export const NOTIFICATION_SOUND_URL = process.env.REACT_APP_NOTIFICATION_SOUND_URL || ''
