import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import {
  GridActionsCellItem,
  GridEventListener,
  GridRowHeightParams,
  GridRowId,
} from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import {
  ControlledCheckbox,
  ControlledTextField,
  FplDataGrid,
  IGridColumn,
} from 'components/common'
import { CreateDriverUserDialog } from 'components/common/Driver'
import { Paths, DEBOUNCE_DELAY } from 'constants/index'
import { SortEnumType, useDeleteDriverMutation } from 'generated/graphql'
import { GET_PRIMARY_DRIVERS } from 'graphql/queries'
import { usePermission } from 'providers'

const Drivers = () => {
  const history = useHistory()
  const [createDriverUserDialog, setCreateDriverUserDialog] = useState<{
    id: number | null
    open: boolean
  }>({
    id: null,
    open: false,
  })
  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
      isActive: true,
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''
  const [isActiveValue] = useDebounce(watch('isActive', true), DEBOUNCE_DELAY)
  const entityName = 'primaryDrivers'

  const [isAllowedToDeleteDriver] = usePermission('DeleteDriver')

  // mutations
  const [deleteDriver, { loading: deleting }] = useDeleteDriverMutation({
    refetchQueries: [
      {
        query: GET_PRIMARY_DRIVERS,
        variables: {
          after: null,
          before: null,
          first: 10,
          last: null,
          order: { name: SortEnumType.Asc },
        },
      },
    ],
  })

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) =>
    history.push(Paths.drivers.updateWithId(id))

  const handleCreateDriver = () => {
    history.push(Paths.drivers.create)
  }

  // ToDo: add confirmation dialog
  const handleDeleteDriver = useCallback(
    (id: GridRowId) => () => {
      deleteDriver({
        variables: {
          driverId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Driver ${id} was deleted with success!`)
        }
      })
    },
    [deleteDriver],
  )

  const handleCreateDriverUserClick = useCallback(
    (id: GridRowId) => () => {
      setCreateDriverUserDialog({
        id: Number(id),
        open: true,
      })
    },
    [setCreateDriverUserDialog],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleGetRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    if (model.addressLine2) {
      return 80 * densityFactor
    } else {
      return 65 * densityFactor
    }
  }, [])

  const handleCloseCreateUserDialog = () => {
    setCreateDriverUserDialog({
      id: null,
      open: false,
    })
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'callSign', headerName: 'Call Sign', minWidth: 200, flex: 1 },
      { field: 'isActive', headerName: 'Active', minWidth: 100, type: 'boolean' },
      {
        field: 'addressLine1',
        headerName: 'Address',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <div>
              <div>{row.addressLine1}</div>
              <div>{row.addressLine2}</div>
              <div>{`${row.city}, ${row.postcode}`}</div>
            </div>
          )
        },
      },
      { field: 'telephoneNumber', headerName: 'Tel No', minWidth: 150 },
      { field: 'email', headerName: 'Email', minWidth: 200 },
      { field: 'licenseType', headerName: 'License Type', minWidth: 150 },
      { field: 'licenseExpiryDate', headerName: 'License Exp. Date', minWidth: 200, flex: 1 },
      {
        field: 'isUsingInternalApp',
        headerName: 'Using internal app',
        maxWidth: 200,
        minWidth: 100,
        sortable: false,
        flex: 1,
        type: 'boolean',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 100,
        type: 'actions',
        getActions: ({ id, row: { isUsingInternalApp } }) => {
          const createUser = [
            <GridActionsCellItem
              key={id}
              label='Create user'
              size='large'
              icon={
                <Tooltip title={'Create user'} arrow>
                  <GroupAddOutlinedIcon />
                </Tooltip>
              }
              onClick={handleCreateDriverUserClick(id)}
            />,
          ]

          const deleteDriver = [
            <GridActionsCellItem
              key={id}
              label='Delete'
              size='large'
              disabled={deleting || !isAllowedToDeleteDriver}
              icon={
                <Tooltip title={'Delete'} arrow>
                  <DeleteIcon />
                </Tooltip>
              }
              onClick={handleDeleteDriver(id)}
            />,
          ]

          return isUsingInternalApp ? deleteDriver : createUser.concat(deleteDriver)
        },
      },
    ],
    [deleting, handleCreateDriverUserClick, handleDeleteDriver, isAllowedToDeleteDriver],
  )

  const filter = useMemo(() => {
    return {
      or: [
        { name: { contains: searchValue } },
        { callSign: { contains: searchValue } },
        { addressLine1: { contains: searchValue } },
        { telephoneNumber: { contains: searchValue } },
        { email: { contains: searchValue } },
      ],
      and: [{ isActive: { eq: isActiveValue } }],
    }
  }, [searchValue, isActiveValue])

  return (
    <>
      <FplDataGrid
        query={GET_PRIMARY_DRIVERS}
        entityName={entityName}
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        toolbar={{
          caption: 'Drivers',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateDriver}>
                New Driver
              </Button>
            </Grid>
          ),
          rightSide: (
            <>
              <Grid item>
                <ControlledCheckbox
                  control={control}
                  name='isActive'
                  label='Active'
                  defaultValue={true}
                  size='small'
                />
              </Grid>

              <Grid item>
                <Box width='210px'>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Box>
              </Grid>
            </>
          ),
        }}
        getRowHeight={handleGetRowHeight}
        onRowClick={handleRowClick}
      />
      <CreateDriverUserDialog
        driverId={createDriverUserDialog.id}
        open={createDriverUserDialog.open}
        onClose={handleCloseCreateUserDialog}
      />
    </>
  )
}

export default Drivers
