import { useMemo } from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'

import { FplDataGrid, FplDialogTitle, IGridColumn } from 'components/common'
import { GET_DRIVER_CONTACTS } from 'graphql/queries'
import { resetPagination } from 'helpers'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
  { field: 'department', headerName: 'Department', minWidth: 200 },
  { field: 'email', headerName: 'Email', minWidth: 200 },
  { field: 'telephoneNumber', headerName: 'Telephone Number', minWidth: 200 },
]

interface IProps {
  openDialog: boolean
  selectedDriverId: string | null
  onSelectContact: (id: number) => void
  onCloseDialog: () => void
}

const SelectContactDialog = (props: IProps) => {
  const entityName = 'driverContacts'
  const { openDialog, selectedDriverId, onSelectContact, onCloseDialog } = props

  const queryVariables = useMemo(() => ({ driverId: Number(selectedDriverId) }), [selectedDriverId])

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    onSelectContact(Number(id))
  }

  const handleCloseDialog = () => {
    onCloseDialog()
    resetPagination(entityName)
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='md'
      scroll='body'
      aria-labelledby='select-driver-contact-dialog'>
      <FplDialogTitle id='select-driver-contact-dialog' onClose={handleCloseDialog}>
        Select Driver Contact
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_DRIVER_CONTACTS}
          entityName={entityName}
          columns={columns}
          queryVariables={queryVariables}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          toolbar={{ caption: 'Driver Contacts' }}
          onRowClick={handleRowClick}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectContactDialog
