import { useMemo } from 'react'

import { ControlledSelector } from 'components/common'
import { useGetBookingCreatorsQuery } from 'generated/graphql'

interface IProps {
  control: any
}

const BookingCreatorsSelector = (props: IProps) => {
  const { control } = props

  const { data: bookingCreatorsData } = useGetBookingCreatorsQuery()

  const bookingCreatorOptions = useMemo(
    () =>
      bookingCreatorsData?.bookingCreators.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName}`,
      })) || [],
    [bookingCreatorsData],
  )

  return (
    <ControlledSelector
      control={control}
      name='createdBy'
      label='Created By'
      options={bookingCreatorOptions}
      emptyValue={{ value: '', label: 'All Users' }}
      displayEmpty
    />
  )
}

export default BookingCreatorsSelector
