import React from 'react'

import { GridEventListener, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'

import {
  ICursorPagination,
  IGridColumn,
  ISortModel,
} from 'components/common/FplDataGrid/FplDataGrid'
import { getPaging, getSortModel } from 'components/common/FplDataGrid/helpers'
import { setDataSort, setPageNumber, setPageSize, setPagination } from 'helpers/localStorage'

interface IProps {
  onRowClick?: GridEventListener<'rowClick'>
  data: any
  entityName: string
  id: string
  setSortModel: React.Dispatch<React.SetStateAction<ISortModel[]>>
  columns: IGridColumn[]
  paginationModel: GridPaginationModel
  setCursorPagination: React.Dispatch<React.SetStateAction<ICursorPagination>>
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>
}

function useFplDataGridHandlers(props: IProps) {
  const {
    onRowClick,
    data,
    entityName,
    id,
    setSortModel,
    columns,
    paginationModel,
    setCursorPagination,
    setPaginationModel,
  } = props

  const handleRowClick: GridEventListener<'rowClick'> = (params, event, details) => {
    onRowClick?.(params, event, details)
  }

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      setSortModel([])
      setDataSort([], id)
    } else {
      const { field, sort } = newModel[0]
      const newSortModel = getSortModel(field, sort, columns)

      if (newSortModel) {
        setDataSort(newSortModel, id)
        setSortModel([newSortModel])
      }
    }
  }

  const handleRefreshClick = () => {
    // Reset pagination states
    setPaginationModel((prev) => {
      setPageNumber(0, id)
      return { page: 0, pageSize: prev.pageSize }
    })

    setCursorPagination((prev) => {
      const paging = getPaging({ first: prev.first })
      setPagination(paging, id)
      return paging
    })
  }

  const handlePageChange = async (model: GridPaginationModel) => {
    const totalCount = data?.[entityName]?.totalCount

    if (paginationModel.pageSize * paginationModel.page > totalCount) {
      return
    }

    const isNewPageSize = model.pageSize !== paginationModel.pageSize

    const isBackDirection = paginationModel.page > model.page

    const paging = isNewPageSize
      ? getPaging({ before: null, after: null, first: model.pageSize })
      : isBackDirection
      ? getPaging({ before: data?.[entityName]?.pageInfo.startCursor, last: model.pageSize })
      : getPaging({ after: data?.[entityName]?.pageInfo.endCursor, first: model.pageSize })

    setPaginationModel(isNewPageSize ? { page: 0, pageSize: model.pageSize } : model)
    setCursorPagination(paging)
    setPagination(paging, id)
    setPageNumber(isNewPageSize ? 0 : model.page, id)
    setPageSize(model.pageSize, id)
  }

  return { handleRowClick, handleSortModelChange, handleRefreshClick, handlePageChange }
}

export default useFplDataGridHandlers
