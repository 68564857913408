import { DATE_FORMAT, TIME_FORMAT } from 'constants/index'

import React, { useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { format, intlFormatDistance, isToday } from 'date-fns'

interface IProps {
  activity: any
  bookingId: string | null
  isAllowedToUpdateActivity: boolean
  isAllowedToDeleteActivity: boolean
  onActivityClick: (id) => void
  onDeleteClick: (id) => void
  onRedirectClick: (bookingId) => void
}

const HistoryRow = (props: IProps) => {
  const {
    activity,
    bookingId,
    isAllowedToUpdateActivity,
    isAllowedToDeleteActivity,
    onActivityClick,
    onDeleteClick,
    onRedirectClick,
  } = props

  const activityDate = activity.date
  const createdAtDate = activity.createdAt

  const dateFormatted = useMemo(() => {
    const isEndDateToday = isToday(new Date(activityDate))
    if (isEndDateToday) {
      return 'Today'
    }

    const intlFormat = intlFormatDistance(new Date(activityDate), new Date())
    return intlFormat.charAt(0).toLocaleUpperCase() + intlFormat.slice(1)
  }, [activityDate])

  const dateFormattedFull = useMemo(() => {
    return format(new Date(activityDate), DATE_FORMAT)
  }, [activityDate])

  const createdAtTimeFormatted = useMemo(() => {
    return format(new Date(createdAtDate), TIME_FORMAT)
  }, [createdAtDate])

  const handleActivityClick = () => {
    onActivityClick(activity.id)
  }

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onDeleteClick(activity.id)
  }

  const handleRedirectClick = () => {
    onRedirectClick(activity.bookingId)
  }

  return (
    <Stack
      sx={{
        padding: 1,
        ':hover': (theme) => ({ backgroundColor: theme.palette.action.hover }),
      }}
      onClick={isAllowedToUpdateActivity ? handleActivityClick : undefined}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <NoteAltOutlinedIcon color='secondary' />

          <Typography variant='body1' color='text.secondary'>
            {/* // ToDo: use user name here instead of email */}
            {`${activity.type} by ${activity.createdBy}`}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Tooltip title={dateFormattedFull}>
            <Typography variant='body1' color='text.secondary'>
              {dateFormatted}
            </Typography>
          </Tooltip>

          <Stack direction='row'>
            {!bookingId && (
              <IconButton aria-label='redirect-activity' onClick={handleRedirectClick}>
                <LaunchIcon />
              </IconButton>
            )}

            {isAllowedToDeleteActivity && (
              <IconButton aria-label='delete-activity' onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={0.5} marginLeft={4}>
        {!bookingId && (
          <Typography variant='subtitle1' fontWeight='medium'>
            {activity?.booking?.ourReference}
          </Typography>
        )}
        <Typography variant='body1'>
          {activity.note} - {createdAtTimeFormatted}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default HistoryRow
