import { ClickAwayListener, List, Paper, Popper } from '@mui/material'

import { MainCard } from '../MainCard'
import { Transitions, TransitionType } from '../Transitions'

interface NavPopperProps {
  isOpen: boolean
  title: string
  matchesXs?: boolean
  anchorEl?: any
  onClickAway: (event: any) => void
  secondary?: React.ReactNode
  children?: any
}

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
}

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none',
}

export const NavPopper = (props: NavPopperProps) => {
  const { isOpen, matchesXs, anchorEl, onClickAway, title, secondary, children } = props

  return (
    <Popper
      placement={matchesXs ? 'bottom' : 'bottom-end'}
      anchorEl={anchorEl}
      role={undefined}
      open={isOpen}
      transition
      disablePortal
      style={{
        maxWidth: '40%',
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? -5 : 0, 0],
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ],
      }}>
      {({ TransitionProps }) => (
        <Transitions type={TransitionType.fade} in={isOpen} {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <MainCard
                title={title}
                border={false}
                elevation={0}
                secondary={secondary}
                content={false}>
                <List
                  component='nav'
                  sx={{
                    p: 0,
                    maxHeight: {
                      xs: '200px',
                      sm: '350px',
                      md: '500px',
                    },
                    overflowY: 'auto',
                    '& .MuiListItemButton-root': {
                      py: 0.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': {
                        ...actionSX,
                        position: 'relative',
                      },
                    },
                  }}>
                  <>{children}</>
                </List>
              </MainCard>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  )
}
