import { Button, Paper, Skeleton, Stack, Typography } from '@mui/material'
import {
  AccountPayableRecordStatus,
  useGetAccountPayableRecordQuery,
  useGetMissingDriverPaymentDataFieldsQuery,
} from 'generated/graphql'
import { useParams, useHistory } from 'react-router-dom'

const MissingPaymentFieldsPanel = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()

  const { data: orderDetails } = useGetAccountPayableRecordQuery({
    variables: { id: Number(id) },
  })

  const driverId = Number(
    orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id,
  )
  const status = orderDetails?.accountPayableRecord?.status
  const disabledEdit =
    status === AccountPayableRecordStatus.PaymentSent ||
    status === AccountPayableRecordStatus.Archived

  const { data: missingPaymentData, loading } = useGetMissingDriverPaymentDataFieldsQuery({
    variables: {
      driverId,
    },
    skip: !driverId,
    fetchPolicy: 'network-only',
  })

  const missingPaymentDataFields = missingPaymentData?.missingDriverPaymentDataFields || []
  const paymentFields = missingPaymentDataFields?.join(', ')

  const handleEditClick = () => {
    history.push(`/suppliers/driver/${driverId}`)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!missingPaymentDataFields.length) {
    return <></>
  }

  return (
    <Paper elevation={4}>
      <Stack direction='row' p={2} alignItems='center' justifyContent='space-between'>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          Missing payment data: {paymentFields}
        </Typography>
        <Button variant='outlined' onClick={handleEditClick} disabled={disabledEdit}>
          EDIT
        </Button>
      </Stack>
    </Paper>
  )
}

export default MissingPaymentFieldsPanel
