import { useEffect, useMemo } from 'react'

import { Grid, Skeleton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import {
  ControlledSelector,
  ControlledTextField,
  ControlledCheckbox,
  ControlledSwitch,
} from 'components/common'
import { CardTitle, DropZoneIcon } from 'components/common/controls'
import { DriverPurchaseCodes, DriverTypeValues, VatCategoryType } from 'constants/index'
import {
  DriverType,
  CreateAndUpdateDriverDtoInput,
  useGetXeroCodesQuery,
  XeroCode,
} from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'

import DriverAverageRating from '../DriverRating/DriverAverageRating'

const getXeroCodeValues = (xeroCodes: XeroCode[] = []) => {
  return xeroCodes.map((c) => ({ value: c.id, label: c.name }))
}

const MainDetails = () => {
  const spacing = 2

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()

  const { data: xeroCodesData, loading: xeroCodeLoading } = useGetXeroCodesQuery()

  const xeroCodes = useMemo(
    () => getXeroCodeValues(xeroCodesData?.xeroCodes as XeroCode[]),
    [xeroCodesData],
  )

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'vatCategoryId') {
        setValue(
          'xeroPurchaseCode',
          value.vatCategoryId === String(VatCategoryType.t1)
            ? DriverPurchaseCodes.vatRegisteredPurchaseCode
            : DriverPurchaseCodes.nonVatRegisteredPurchaseCode,
        )
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <>
      <CardTitle>Driver Details</CardTitle>
      <Grid container spacing={spacing} justifyContent='center'>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                label='Name'
                name='name'
                required
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Restricted to='ViewUpdateBookingDriverRating'>
              <DriverAverageRating />
            </Restricted>
            <Grid item xs={12}>
              <ControlledSelector
                control={control}
                label='Driver Type'
                name='driverType'
                options={DriverTypeValues}
                defaultValue={DriverType.Contract}
              />
            </Grid>
            <Grid item xs={12}>
              {xeroCodeLoading ? (
                <Skeleton animation='wave' />
              ) : (
                <ControlledSelector
                  control={control}
                  name='xeroCodeId'
                  label='Service Code'
                  options={xeroCodes}
                  error={!!errors.xeroCodeId}
                  helperText={errors.xeroCodeId?.message}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name='courierExchangeMemberId'
                label='Courier Exchange Member ID'
                error={!!errors.courierExchangeMemberId}
                helperText={errors.courierExchangeMemberId?.message}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} spacing={spacing}>
          <Grid item xs={12}>
            <DropZoneIcon dropzoneText='Driver Icon' />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name='xeroPurchaseCode'
              label='Purchase Code'
              defaultValue=''
              required
              error={!!errors.xeroPurchaseCode}
              helperText={errors.xeroPurchaseCode?.message}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={spacing}>
          <Grid item xs={6}>
            <ControlledCheckbox control={control} label='Active' name='isActive' />
          </Grid>
          <Grid item xs={6}>
            <Restricted to='ViewOnboardingOptions'>
              <ControlledSwitch control={control} name='isOnboardingCompleted' label='Onboarding' />
            </Restricted>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MainDetails
