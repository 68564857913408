import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Divider, Stack, Typography } from '@mui/material'
import Joi from 'joi'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { DigitPattern } from 'constants/index'
import {
  GetAccountPayableInvoiceDocument,
  useGetAccountPayableRecordQuery,
  useGetPaymentRecordQuery,
  useUpdateDriverInformationMutation,
} from 'generated/graphql'
import { Restricted } from 'providers'

import DocumentActionPanel from '../DocumentActionPanel/DocumentActionPanel'
import PaperPanel from '../PaperPanel'
import OrderDetailsEditForm from './OrderDetailsEditForm'
import OrderDetailsItems from './OrderDetailsItems'
import OrderDetailsStepper from './OrderDetailsStepper'

type OrderDetailsForm = {
  xeroPurchaseCode: string
  vatCategoryId: string
}

function PurchaseCodeFormValidationSchema() {
  return Joi.object({
    xeroPurchaseCode: Joi.string().required().pattern(new RegExp(DigitPattern)).messages({
      'string.base': 'Mandatory field',
      'string.empty': 'Mandatory field',
      'string.pattern.base': 'Only digits are allowed',
    }),
    vatCategoryId: Joi.string().required().label('Vat category'),
  })
}
const schema = PurchaseCodeFormValidationSchema()

function OrderDetailsPanel() {
  const { id } = useParams<{ id?: string }>()

  // Order details form
  const methods = useForm<OrderDetailsForm>({
    resolver: joiResolver(schema),
    defaultValues: {
      xeroPurchaseCode: '',
      vatCategoryId: '',
    },
    mode: 'onChange',
  })

  const [isPurchaseCodeEditable, setPurchaseCodeEditable] = useState(false)

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods

  // Queries
  const { data: orderDetails, loading } = useGetAccountPayableRecordQuery({
    variables: { id: Number(id) },
  })

  const bookingInvoiceId =
    orderDetails && Number(orderDetails?.accountPayableRecord?.bookingInvoice.id)

  const { data: payment, loading: paymentLoading } = useGetPaymentRecordQuery({
    variables: { bookingInvoiceId },
    skip: !bookingInvoiceId,
  })

  const [updateDriverInformation, { loading: driverInformationLoading }] =
    useUpdateDriverInformationMutation({
      onError: () => {
        toast.error('Something went wrong. Please try again')
      },
      onCompleted: () => {
        toast.success('Changes saved successfully')
        setPurchaseCodeEditable(false)
      },
      // refetch invoice with new VAT category after update
      refetchQueries: [
        {
          query: GetAccountPayableInvoiceDocument,
          variables: {
            accountPayableRecordId: Number(id),
          },
        },
      ],
    })

  const onSubmit = async (data: OrderDetailsForm) => {
    const accountPayableRecordId = orderDetails?.accountPayableRecord?.id

    if (!accountPayableRecordId) {
      return
    }

    const result = await updateDriverInformation({
      variables: {
        input: {
          id: Number(accountPayableRecordId),
          xeroPurchaseCode: data.xeroPurchaseCode,
          vatCategoryId: Number(data.vatCategoryId),
        },
      },
    })

    const driverInfo = result.data?.updateDriverInformation

    if (driverInfo) {
      reset({
        xeroPurchaseCode: driverInfo.xeroPurchaseCode ?? '',
        vatCategoryId: String(driverInfo.vatCategoryId),
      })
    }
  }

  if (loading || paymentLoading) {
    return <LoadingButton loading={loading || paymentLoading} />
  }

  return (
    <Stack spacing={2}>
      <Typography variant='h6'> Order Details</Typography>
      <PaperPanel>
        <Stack spacing={2} p={8}>
          <Typography variant='h6'>
            {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.name}(
            {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id ?? 'N/A'})
          </Typography>
          <Divider role='presentation' />
          <FormProvider {...methods}>
            <OrderDetailsItems
              orderDetails={{
                fplRef: orderDetails?.accountPayableRecord?.bookingInvoice.booking.ourReference,
                customerRef:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.customerReference,
                loadId:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.courierExchangeLoadId,
                transactionId: payment?.paymentRecord?.transactionId,
                totalPrice: {
                  symbol: orderDetails?.accountPayableRecord?.currency?.symbol ?? '',
                  price: orderDetails?.accountPayableRecord?.gross,
                },
                tariff: orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.name,
                tariffCategory:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.tariffCategory
                    .name,
              }}
              OrderDetailsEditForm={
                <OrderDetailsEditForm
                  isPurchaseCodeEditable={isPurchaseCodeEditable}
                  setPurchaseCodeEditable={setPurchaseCodeEditable}
                  accountPayableRecordStatus={orderDetails?.accountPayableRecord?.status}
                  driverId={
                    orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id
                  }
                />
              }
            />
          </FormProvider>

          <OrderDetailsStepper
            adresses={orderDetails?.accountPayableRecord?.bookingInvoice.booking.addresses.map(
              (address) => ({
                id: address.id,
                at: address?.at ?? '',
                by: address?.by ?? '',
                type: address.type,
                adress: {
                  id: address.address.id,
                  name: address.address.name,
                  adressLine1: address.address.addressLine1,
                  adressLine2: address.address.addressLine2,
                  postcode: address.address.postcode,
                  city: address.address.city,
                  countryName: address.address.country.name,
                },
              }),
            )}
          />
        </Stack>
      </PaperPanel>
      <DocumentActionPanel
        documentName='Order Details'
        createdAt={new Date(orderDetails?.accountPayableRecord?.bookingInvoice.invoiceDate)}>
        <Restricted to='ViewAccountsPayableRecordDocumentActions'>
          <LoadingButton
            loading={driverInformationLoading}
            variant='contained'
            disabled={!isValid || !isDirty || driverInformationLoading}
            onClick={handleSubmit(onSubmit)}>
            Save
          </LoadingButton>
        </Restricted>
      </DocumentActionPanel>
    </Stack>
  )
}

export default OrderDetailsPanel
