import { DigitPattern } from 'constants/index'
import Joi from 'joi'

// ADD: here any other validation rules to check driver while allocation
function allocationValidationSchema() {
  return Joi.object({
    xeroPurchaseCode: Joi.string().required().pattern(new RegExp(DigitPattern)),
    vatCategoryId: Joi.number().required(),
  })
}

export default allocationValidationSchema
