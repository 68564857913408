import { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { CreateDriverUserDialog } from 'components/common/Driver'
import { DEBOUNCE_DELAY } from 'constants/index'
import { DriverFilterInput } from 'generated/graphql'
import { GET_SUB_DRIVERS } from 'graphql/queries'

import CreateUpdateSubDrivers from './CreateUpdateSubDrivers/CreateUpdateSubDrivers'

interface IProps {
  driverId: number
}

const SubDrivers = (props: IProps) => {
  const { driverId } = props
  const [selectedSubDriverId, setSelectedSubDriverId] = useState<number | null>(null)
  const [openSubDriverDialog, setOpenSubDriverDialog] = useState(false)
  const [createDriverUserDialog, setCreateDriverUserDialog] = useState<{
    id: number | null
    open: boolean
  }>({ id: null, open: false })

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const handleCreateDriverUserClick = useCallback(
    (id: GridRowId) => () => {
      setCreateDriverUserDialog({
        id: Number(id),
        open: true,
      })
    },
    [setCreateDriverUserDialog],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'callSign', headerName: 'Call Sign', minWidth: 200, flex: 1 },
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
      { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
      {
        field: 'isUsingInternalApp',
        headerName: 'Using internal app',
        sortable: false,
        maxWidth: 200,
        flex: 1,
        type: 'boolean',
      },
      { field: 'telephoneNumber', headerName: 'Telephone Number', minWidth: 200, flex: 1 },
      { field: 'isActive', headerName: 'Active', type: 'boolean', minWidth: 200, flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        type: 'actions',
        getActions: ({ id, row: { isUsingInternalApp } }) =>
          isUsingInternalApp
            ? []
            : [
                <GridActionsCellItem
                  key={id}
                  label='Create user'
                  size='large'
                  icon={
                    <Tooltip title={'Create user'} arrow>
                      <GroupAddOutlinedIcon />
                    </Tooltip>
                  }
                  onClick={handleCreateDriverUserClick(id)}
                />,
              ],
      },
    ],
    [handleCreateDriverUserClick],
  )

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedSubDriverId(Number(id))
    setOpenSubDriverDialog(true)
  }

  const handleCreateSubDriver = () => {
    setSelectedSubDriverId(null)
    setOpenSubDriverDialog(true)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseSubDriverDialog = () => {
    setOpenSubDriverDialog(false)
  }

  const handleCloseCreateUserDialog = () => {
    setCreateDriverUserDialog({
      id: null,
      open: false,
    })
  }

  const filter: DriverFilterInput = useMemo(() => {
    return {
      or: [
        { name: { contains: searchValue } },
        { callSign: { contains: searchValue } },
        { addressLine1: { contains: searchValue } },
        { telephoneNumber: { contains: searchValue } },
        { email: { contains: searchValue } },
      ],
    }
  }, [searchValue])

  return (
    <>
      <FplDataGrid
        query={GET_SUB_DRIVERS}
        queryVariables={{ driverId }}
        skipQuery={!driverId}
        entityName='subDrivers'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Sub-drivers',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateSubDriver}>
                New Sub-driver
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />
      {openSubDriverDialog && (
        <CreateUpdateSubDrivers
          subDriverId={selectedSubDriverId}
          driverId={driverId}
          openDriverDialog={openSubDriverDialog}
          onCloseDialog={handleCloseSubDriverDialog}
        />
      )}
      {createDriverUserDialog.open && (
        <CreateDriverUserDialog
          driverId={createDriverUserDialog.id}
          open={createDriverUserDialog.open}
          onClose={handleCloseCreateUserDialog}
        />
      )}
    </>
  )
}

export default SubDrivers
