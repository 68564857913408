import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { GridColDef, GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { FplDialogTitle } from 'components/common'
import { FplDataGrid } from 'components/common/FplDataGrid'
import { DATE_TIME_FORMAT, Paths } from 'constants/index'
import { InvoiceType } from 'generated/graphql'
import { GET_INVOICE_BOOKINGS } from 'graphql/queries'
import { resetPagination } from 'helpers'

interface IProps {
  invoiceNumber: number
  open: boolean
  handleCloseDialog: () => void
}

const InvoiceBookingsDialog = (props: IProps) => {
  const entityName = 'invoiceBookings'
  const { invoiceNumber, open, handleCloseDialog } = props
  const { push } = useHistory()

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Booking Id', width: 140 },
    { field: 'ourReference', headerName: 'Our Reference', width: 140 },
    { field: 'customerReference', headerName: 'Customer reference', width: 140 },
    { field: 'status', headerName: 'Status', width: 140 },
    {
      field: 'createdAt',
      headerName: 'Created at',
      width: 140,
      valueGetter: ({ value }) => format(parseJSON(value as string), DATE_TIME_FORMAT),
    },
  ]

  // handlers

  const handleRowClick: GridEventListener<'rowClick'> = (params) =>
    push(Paths.bookings.updateWithId(params.id))

  const handleClose = () => {
    handleCloseDialog
    resetPagination(entityName)
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='invoice-bookings-dialog-title'>
      <FplDialogTitle id='invoice-bookings-dialog-title' onClose={handleClose}>
        Invoice ({invoiceNumber}) Bookings
      </FplDialogTitle>

      <DialogContent>
        <FplDataGrid
          query={GET_INVOICE_BOOKINGS}
          entityName={entityName}
          columns={columns}
          onRowClick={handleRowClick}
          queryVariables={{ invoiceNumber: invoiceNumber, invoiceType: InvoiceType.Purchase }}
          pageOptions={{
            pageSize: 5,
            rowsPerPage: [5, 50, 100],
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvoiceBookingsDialog
