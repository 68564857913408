import { useReactiveVar } from '@apollo/client'
import { GridEventListener, GridSortItem } from '@mui/x-data-grid'
import { useHistory } from 'react-router-dom'

import { FplDataGrid } from 'components/common'
import { Paths } from 'constants/index'
import { AccountPayableRecordFilterInput } from 'generated/graphql'
import { GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY_WITH_BATCHLINE } from 'graphql/queries'
import { expandableGridClickedRowIdVar } from 'graphql/reactiveVariables'
import useGetAccountsPayableCompactedColumns from 'hooks/accountsPayable/useGetAccountsPayableCompactedColumns'

const descendingOrderByStatus: GridSortItem = {
  field: 'status',
  sort: 'desc',
}

function AccountPayableCompactedGrid() {
  const [columns] = useGetAccountsPayableCompactedColumns()
  const history = useHistory()

  const expandableGridClickedRowId = useReactiveVar(expandableGridClickedRowIdVar)

  const filterByBatchLineId: AccountPayableRecordFilterInput = {
    bookingInvoiceBatchLine: { id: { eq: Number(expandableGridClickedRowId) } },
  }

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    history.push(Paths.accountsPayable.updateWithId(id))
  }

  return (
    <FplDataGrid
      overwriteId={'accountPayableRecords'}
      query={GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY_WITH_BATCHLINE}
      filter={filterByBatchLineId}
      isHideOldData={true}
      refetchWithDelay
      entityName='accountPayableRecords'
      toolbar={{
        caption: `Account Payable Records for Batch Line ID ${expandableGridClickedRowId}`,
      }}
      columns={columns}
      defaultOrder={descendingOrderByStatus}
      onRowClick={handleRowClick}
    />
  )
}

export default AccountPayableCompactedGrid
