import { useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Stack } from '@mui/material'
import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import {
  AccountPayableRecordStatus,
  useGetDriverOrderDetailsQuery,
  useGetVatCategoriesQuery,
} from 'generated/graphql'

import { useFormContext } from 'react-hook-form'
import { VatCategoryType } from 'constants/index'

type OrderDetailsEditFormProps = {
  accountPayableRecordStatus?: AccountPayableRecordStatus
  driverId?: string
  isPurchaseCodeEditable: boolean
  setPurchaseCodeEditable: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderDetailsEditForm = ({
  accountPayableRecordStatus,
  driverId,
  isPurchaseCodeEditable,
  setPurchaseCodeEditable,
}: OrderDetailsEditFormProps) => {
  const disabledEditStatus =
    accountPayableRecordStatus !== AccountPayableRecordStatus.Flagged &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.UnderReview &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.ScheduledPayment &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.PaymentFailed

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    resetField,
  } = useFormContext<any>()

  // Queries
  const { data: driverPurchaseCode } = useGetDriverOrderDetailsQuery({
    variables: {
      driverId: Number(driverId),
    },
    skip: !driverId,
    onCompleted: (data) => {
      if (data.driver) {
        reset({
          xeroPurchaseCode: data.driver.xeroPurchaseCode ?? '',
          vatCategoryId: data.driver.vatCategoryId ?? VatCategoryType.t0,
        })
      }
    },
  })
  const purchaseCode = driverPurchaseCode?.driver?.xeroPurchaseCode

  const { data: vatCategoriesData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const vatCategoryItems: ISelectorOptions = useMemo(() => {
    if (!vatCategoriesLoading && vatCategoriesData?.vatCategories) {
      return vatCategoriesData.vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
    }

    return []
  }, [vatCategoriesData, vatCategoriesLoading])

  const handleEditPurchaseCode = () => {
    setPurchaseCodeEditable(true)
  }

  const handleCancelEditPurchaseCode = () => {
    setPurchaseCodeEditable(false)
    resetField('xeroPurchaseCode', { keepDirty: false, keepError: false })
  }

  return (
    <>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>Purchase Code:</Box>

        <ControlledTextField
          control={control}
          name='xeroPurchaseCode'
          label=''
          variant='standard'
          disabled={!isPurchaseCodeEditable || disabledEditStatus}
          sx={{ background: disabledEditStatus ? 'transparent' : 'auto' }}
          endAdornment={
            isPurchaseCodeEditable ? (
              <IconButton size='small' onClick={handleCancelEditPurchaseCode}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                disabled={disabledEditStatus}
                onClick={handleEditPurchaseCode}>
                <EditIcon />
              </IconButton>
            )
          }
          error={!!errors.xeroPurchaseCode}
          helperText={errors.xeroPurchaseCode?.message}
        />
      </Stack>

      <Stack spacing={1} direction={'row'} alignItems='baseline'>
        <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>VAT:</Box>
        <ControlledSelector
          disabled={disabledEditStatus}
          size='small'
          variant='standard'
          control={control}
          name='vatCategoryId'
          displayEmpty
          options={vatCategoryItems}
        />
      </Stack>
    </>
  )
}

export default OrderDetailsEditForm
