import { useCallback, useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Grid, Paper } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { toast } from 'react-toastify'

import { DATE_FORMAT } from 'constants/index'
import {
  GetNotificationDocument,
  NotificationFilterInput,
  useDeleteNotificationMutation,
} from 'generated/graphql'
import { GET_PAGED_NOTIFICATIONS } from 'graphql/queries'

import { CardTitle } from '../controls'
import { FplDataGrid, IGridColumn } from '../FplDataGrid'
import { usePermission } from 'providers'

export interface IUserNotification {
  username?: string
}

export const UserNotifications = (props: IUserNotification) => {
  const { username } = props

  const filter: NotificationFilterInput | null = useMemo(() => {
    return { username: { eq: username } }
  }, [username])

  const [isAllowedToDeleteNotification] = usePermission('DeleteNotification')

  // mutations
  const [deleteNotification, { loading: deleting }] = useDeleteNotificationMutation({
    refetchQueries: [{ query: GetNotificationDocument, variables: { where: filter } }],
    onCompleted: () => toast.success('Notification was deleted with success'),
  })

  // handlers
  const handleDeleteNotification = useCallback(
    (id: GridRowId) => () => {
      deleteNotification({ variables: { id: Number(id) } })
    },
    [deleteNotification],
  )

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Subject', flex: 0.5 },
      { field: 'description', headerName: 'Description', flex: 1 },
      { field: 'priority', headerName: 'Priority', flex: 0.5 },
      { field: 'isRead', headerName: 'Readded', flex: 0.5 },
      {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
      },
      { field: 'deliveryStatus', headerName: 'Delivery Status', flex: 0.5 },
      { field: 'deliveryStatusLog', headerName: 'Delivery Log', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 110,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={`delete-notification-${id}`}
            label='Delete'
            size='large'
            disabled={deleting || !isAllowedToDeleteNotification}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteNotification(id)}
          />,
        ],
      },
    ],
    [deleting, handleDeleteNotification, isAllowedToDeleteNotification],
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant='outlined'>
          <Box p={2}>
            <CardTitle>{username ? `${username} Notifications` : 'Loading...'}</CardTitle>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {username && (
          <FplDataGrid
            query={GET_PAGED_NOTIFICATIONS}
            entityName='notifications'
            columns={columns}
            filter={filter}
          />
        )}
      </Grid>
    </Grid>
  )
}
