import { useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import { ConfirmDialog } from 'components/common'
import {
  AccountPayableRecordStatus,
  useCreatePaymentMutation,
  useGetAccountPayableDriverNameQuery,
  useGetMissingDriverPaymentDataFieldsQuery,
  useUpdateAccountPayableRecordStatusMutation,
} from 'generated/graphql'
import { Restricted } from 'providers'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function DriverPanel() {
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()
  const [confirmState, setConfirmState] = useState({
    approve: false,
    flag: false,
    payedExternally: false,
  })

  // Queries
  const {
    data: accountPayableData,
    loading: apLoading,
    refetch,
  } = useGetAccountPayableDriverNameQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  })
  const driverName =
    accountPayableData?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.name
  const bookingReference =
    accountPayableData?.accountPayableRecord?.bookingInvoice.booking.ourReference
  const batchId = accountPayableData?.accountPayableRecord?.bookingInvoiceBatchLine?.batchId
  const statusLabel = accountPayableData?.accountPayableRecord?.statusLabel
  const driverId = Number(
    accountPayableData?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id,
  )

  const { data: missingPaymentData } = useGetMissingDriverPaymentDataFieldsQuery({
    variables: {
      driverId,
    },
    skip: !driverId,
    fetchPolicy: 'network-only',
  })

  const missingPaymentDataFields = missingPaymentData?.missingDriverPaymentDataFields || []
  const hasMissingPaymentDataFields = missingPaymentDataFields.length > 0

  // Mutations
  const [createPayment, { loading: creatingPayment }] = useCreatePaymentMutation({
    onCompleted: () => {
      refetch({ id: Number(id) })
      toast.success('Payment approved')
    },
    onError: () => {
      toast.error('Error approving payment')
    },
  })

  const [updateAccountPayableRecordStatus] = useUpdateAccountPayableRecordStatusMutation({
    onCompleted: () => {
      refetch({ id: Number(id) })
    },
  })

  const [disableFlag, disableApprove] = useMemo(() => {
    if (accountPayableData?.accountPayableRecord) {
      const isUnderReview =
        accountPayableData.accountPayableRecord.status === AccountPayableRecordStatus.UnderReview
      const isFlagged =
        accountPayableData.accountPayableRecord.status === AccountPayableRecordStatus.Flagged
      return [!isUnderReview, !isUnderReview && !isFlagged]
    }
    return [true, true]
  }, [accountPayableData?.accountPayableRecord])

  // handlers
  const handleApprove = () => {
    if (id) {
      createPayment({
        variables: {
          accountPayableId: id,
        },
      })
    }
  }

  const handleFlag = () => {
    updateAccountPayableRecordStatus({
      variables: {
        input: {
          id: Number(id),
          status: AccountPayableRecordStatus.Flagged,
        },
      },
    })
  }

  const handleMarkAsPayedExternally = () => {
    updateAccountPayableRecordStatus({
      variables: {
        input: {
          id: Number(id),
          status: AccountPayableRecordStatus.Archived,
          payedExternally: true,
        },
      },
    })
  }

  const handleCloseClick = () => {
    const previousUrl = localStorage.getItem('previousUrl')
    if (previousUrl) {
      const url = new URL(previousUrl)
      history.replace(url.pathname + url.hash)
    }
    localStorage.removeItem('previousUrl')
  }

  const handlePayedExternallyClick = () =>
    setConfirmState((prevState) => ({
      ...prevState,
      payedExternally: true,
    }))

  const handleFlagClick = () =>
    setConfirmState((prevState) => ({
      ...prevState,
      flag: true,
    }))

  const handleApproveAllClick = () =>
    setConfirmState((prevState) => ({
      ...prevState,
      approve: true,
    }))

  return (
    <Paper elevation={4}>
      <ConfirmDialog
        open={confirmState.approve}
        onConfirm={handleApprove}
        setOpen={(open) => setConfirmState((prevState) => ({ ...prevState, approve: open }))}
        title='Approve Account Payable Record'
        message={`You chose to approve the selected Record: ${accountPayableData?.accountPayableRecord?.bookingInvoice.booking.ourReference}`}
        option1='Cancel'
        option2='Approve'
      />
      <ConfirmDialog
        open={confirmState.flag}
        onConfirm={handleFlag}
        setOpen={(open) => setConfirmState((prevState) => ({ ...prevState, flag: open }))}
        title='Flag Account Payable Record'
        message={`You chose to flag the selected Record: ${accountPayableData?.accountPayableRecord?.bookingInvoice.booking.ourReference}`}
        option1='Cancel'
        option2='Confirm'
      />
      <ConfirmDialog
        open={confirmState.payedExternally}
        onConfirm={handleMarkAsPayedExternally}
        setOpen={(open) =>
          setConfirmState((prevState) => ({ ...prevState, payedExternally: open }))
        }
        title='Mark as Payed Externally'
        message={`When you mark an Account Payable Record as 'Paid Externally', it will be archived. After this action, no further actions can be performed on the Record. Use this option when a Booking has been allocated to a Network Partner, and the payment was made externally. Do you wish to proceed?`}
        option1='Cancel'
        option2='Ok'
      />
      <Stack direction='row' p={2} alignItems='center' justifyContent='space-between'>
        <Stack>
          <Typography variant='h6'>{driverName}</Typography>
          <Typography>
            {bookingReference}
            {batchId && (
              <>
                {' — Batch ID: '}
                {batchId}
              </>
            )}
          </Typography>
          <Typography color={(theme) => theme.palette.error.main}>{statusLabel}</Typography>
        </Stack>
        <Stack direction='row' alignSelf='stretch' spacing={2}>
          <Stack direction='row' alignSelf='center' spacing={2}>
            <Restricted to='ViewAccountsPayableRecordDocumentActions'>
              <LoadingButton
                disabled={disableApprove}
                variant='outlined'
                color='primary'
                onClick={handlePayedExternallyClick}>
                Mark as Payed Externally
              </LoadingButton>
              <LoadingButton
                disabled={disableFlag}
                loading={creatingPayment || apLoading}
                variant='outlined'
                color='secondary'
                onClick={handleFlagClick}>
                Flag
              </LoadingButton>
              <LoadingButton
                disabled={disableApprove || hasMissingPaymentDataFields}
                loading={creatingPayment || apLoading}
                variant='outlined'
                onClick={handleApproveAllClick}>
                Approve
              </LoadingButton>
            </Restricted>
          </Stack>
          <Box>
            <IconButton onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default DriverPanel
