import { useState } from 'react'

import { AppBar, Tabs, Tab, Box, Tooltip } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import { TabPanel } from 'components/common'
import * as Constants from 'constants/index'
import { CloneProps, isNumber, useHashToSelectTab } from 'helpers'

import { AddressList } from './AddressList'
import { Contacts } from './Contacts'
import { CustomerDetails } from './CustomerDetails'
import { CustomerInvoices } from './CustomerInvoices'
import { CustomerNotifications } from './CustomerNotifications'
import { CustomerSop } from './CustomerSop'
import { CustomerTariffs } from './CustomerTariffs'
import { ReasonCodes } from './ReasonCodes'
import { ReferencesAndNotes } from './ReferencesAndNotes'
import { SubCustomers } from './SubCustomers'
import { PermissionType, Restricted } from 'providers'

enum TabId {
  Details = 0,
  AddressList,
  Notifications,
  ReferenceAndNotes,
  Contacts,
  Tariffs,
  Invoices,
  SubCustomers,
  Sop,
  ReasonCodes,
}

const tabsList = [
  { id: TabId.Details, label: 'Customer Details', hash: '#customer-details', policy: null },
  { id: TabId.AddressList, label: 'Address List', hash: '#address-list', policy: null },
  { id: TabId.Notifications, label: 'Notifications', hash: '#notifications', policy: null },
  {
    id: TabId.ReferenceAndNotes,
    label: 'References & Notes',
    hash: '#references-and-notes',
    policy: null,
  },
  { id: TabId.Contacts, label: 'Contacts', hash: '#contacts', policy: null },
  {
    id: TabId.Tariffs,
    label: 'Tariffs',
    hash: '#tariffs',
    policy: 'ViewCustomerTariffs' as PermissionType,
  },
  {
    id: TabId.Invoices,
    label: 'Invoices',
    hash: '#invoices',
    policy: 'ViewCustomerInvoices' as PermissionType,
  },
  { id: TabId.SubCustomers, label: 'Sub Customers', hash: '#sub-customers', policy: null },
  { id: TabId.Sop, label: 'Sop', hash: '#sop', policy: null },
  { id: TabId.ReasonCodes, label: 'Reason Codes', hash: '#reason-codes', policy: null },
]

const primaryTab = tabsList[0]
const secondaryTabs = tabsList.filter((_, index) => index !== 0)

function commonProps(index: any) {
  return {
    id: `customer-tab-${index}`,
    'aria-controls': `customer-tabpanel-${index}`,
  }
}

const CustomerTab = () => {
  const [tabValue, setTabValue] = useState<number | false>(false)
  const { id } = useParams<{ id?: string }>()

  useHashToSelectTab(tabsList, setTabValue)

  let mode = Constants.Mode.Create

  if (id === Constants.PARAM_NEW) {
    mode = Constants.Mode.Create
  } else if (id && isNumber(id)) {
    mode = Constants.Mode.Update
  }

  const customerId = mode === Constants.Mode.Update ? Number(id) : null

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          aria-label='customer tabs'
          variant='scrollable'
          TabIndicatorProps={{
            style: {
              transition: 'none',
            },
          }}>
          <Tab
            label={primaryTab.label}
            {...commonProps(primaryTab.id)}
            component={Link}
            to={{ hash: primaryTab.hash }}
          />
          {mode === Constants.Mode.Create ? (
            <CloneProps>
              {(tabProps) => (
                <Tooltip title='Available only for existing customers'>
                  <Box component='span'>
                    {secondaryTabs.map((tab) =>
                      tab.policy ? (
                        <Restricted key={tab.id} to={tab.policy}>
                          <Tab
                            {...tabProps}
                            label={tab.label}
                            key={tab.id}
                            {...commonProps(tab.id)}
                            disabled
                          />
                        </Restricted>
                      ) : (
                        <Tab
                          {...tabProps}
                          label={tab.label}
                          key={tab.id}
                          {...commonProps(tab.id)}
                          disabled
                        />
                      ),
                    )}
                  </Box>
                </Tooltip>
              )}
            </CloneProps>
          ) : (
            secondaryTabs.map((tab) =>
              tab.policy ? (
                <Restricted key={tab.id} to={tab.policy}>
                  <Tab
                    label={tab.label}
                    key={tab.id}
                    {...commonProps(tab.id)}
                    component={Link}
                    to={{ hash: tab.hash }}
                  />
                </Restricted>
              ) : (
                <Tab
                  label={tab.label}
                  key={tab.id}
                  {...commonProps(tab.id)}
                  component={Link}
                  to={{ hash: tab.hash }}
                />
              ),
            )
          )}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={TabId.Details}>
        <CustomerDetails customerId={customerId} mode={mode} />
      </TabPanel>
      {mode === Constants.Mode.Update && customerId && (
        <>
          <TabPanel value={tabValue} index={TabId.AddressList}>
            <AddressList customerId={customerId} />
          </TabPanel>

          <TabPanel value={tabValue} index={TabId.Notifications}>
            <CustomerNotifications customerId={customerId} />
          </TabPanel>

          <TabPanel value={tabValue} index={TabId.ReferenceAndNotes}>
            <ReferencesAndNotes customerId={customerId} />
          </TabPanel>

          <TabPanel value={tabValue} index={TabId.Contacts}>
            <Contacts customerId={customerId} />
          </TabPanel>

          <Restricted to='ViewCustomerTariffs'>
            <TabPanel value={tabValue} index={TabId.Tariffs}>
              <CustomerTariffs customerId={customerId} />
            </TabPanel>
          </Restricted>
          <Restricted to='ViewCustomerInvoices'>
            <TabPanel value={tabValue} index={TabId.Invoices}>
              <CustomerInvoices customerId={customerId} />
            </TabPanel>
          </Restricted>

          <TabPanel value={tabValue} index={TabId.SubCustomers}>
            <SubCustomers customerId={customerId} />
          </TabPanel>

          <TabPanel value={tabValue} index={TabId.Sop}>
            <CustomerSop customerId={customerId} />
          </TabPanel>

          <TabPanel value={tabValue} index={TabId.ReasonCodes}>
            <ReasonCodes customerId={customerId} />
          </TabPanel>
        </>
      )}
    </div>
  )
}

export default CustomerTab
