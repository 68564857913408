import React, { useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { VehicleFilterInput } from 'generated/graphql'
import { GET_PAGED_VEHICLES } from 'graphql/queries'

import { CreateUpdateVehicle } from '../CreateUpdateVehicle'

const columns: IGridColumn[] = [
  { field: 'registrationNumber', headerName: 'Registration No', minWidth: 200, flex: 1 },
  {
    field: 'vehicleType',
    headerName: 'Vehicle Type',
    minWidth: 200,
    flex: 1,
    valueGetter: ({ value }) => value?.name,
    sortPath: ['vehicleType', 'name'],
  },
  { field: 'manufacturer', headerName: 'Manufacturer', minWidth: 200, flex: 1 },
  { field: 'model', headerName: 'Model', minWidth: 200, flex: 1 },
  {
    field: 'dateOfManufacture',
    headerName: 'Year',
    minWidth: 200,
    flex: 1,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
  },
  { field: 'colour', headerName: 'Colour', minWidth: 200, flex: 1 },
]

interface IProps {
  driverId: number | null
}

const Vehicles = (props: IProps) => {
  const { driverId } = props
  const [selectedVehicleId, setSelectedVehicleId] = React.useState<number | null>(null)
  const [openVehicleDialog, setOpenVehicleDialog] = React.useState(false)

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedVehicleId(Number(id))
    setOpenVehicleDialog(true)
  }

  const handleCreateVehicle = () => {
    setSelectedVehicleId(null)
    setOpenVehicleDialog(true)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const handleCloseVehicleDialog = () => {
    setOpenVehicleDialog(false)
  }

  const filter: VehicleFilterInput = useMemo(() => {
    return {
      or: [{ registrationNumber: { contains: searchValue } }],
    }
  }, [searchValue])

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_VEHICLES}
        queryVariables={{ driverId }}
        skipQuery={!driverId}
        entityName='vehicles'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'id', sort: 'asc' }}
        toolbar={{
          caption: 'Vehicles',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleCreateVehicle}>
                New Vehicle
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openVehicleDialog && (
        <CreateUpdateVehicle
          driverId={driverId as number}
          vehicleId={selectedVehicleId}
          openDriverDialog={openVehicleDialog}
          handleCloseDialog={handleCloseVehicleDialog}
        />
      )}
    </>
  )
}

export default Vehicles
