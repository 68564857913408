import { Mode } from 'constants/Mode'

import { useEffect } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { FplDialogTitle, LoadingBackdrop } from 'components/common'
import {
  ActivityType,
  CreateUpdateActivityDtoInput,
  useCreateActivityMutation,
  useGetActivityQuery,
  useUpdateActivityMutation,
} from 'generated/graphql'
import Joi from 'joi'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import ActivityForm from './ActivityForm'

const createUpdateActivitySchema = Joi.object({
  type: Joi.string()
    .valid(...Object.values(ActivityType))
    .required()
    .label('Activity type'),
  date: Joi.date().required().label('Date'),
  note: Joi.string().required().label('Note'),
})

interface IProps {
  id: number | null
  bookingId?: string | null
  openDialog: boolean
  onCloseDialog: () => any
}

const CreateUpdateActivity = (props: IProps) => {
  const { id, bookingId, openDialog, onCloseDialog } = props

  const methods = useForm<CreateUpdateActivityDtoInput>({
    shouldUnregister: true,
    defaultValues: {
      type: ActivityType.Note,
      date: null,
      note: '',
    },
    resolver: joiResolver(createUpdateActivitySchema),
    mode: 'onChange',
  })

  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = methods

  // queries
  const { data, loading } = useGetActivityQuery({
    variables: { id },
    skip: !id,
  })

  // mutations
  const [createActivity, { loading: creatingActivity }] = useCreateActivityMutation({
    onCompleted: (data) => {
      toast.success(`Activity ${data.createActivity?.id} was logged with success.`)
      onCloseDialog()
    },
    update(cache, { data }) {
      if (data?.createActivity) {
        cache.modify({
          fields: {
            activities(existingActivities = {}, { toReference }) {
              const edges = [
                ...existingActivities.edges,
                { __typename: 'ActivitiesEdge', node: toReference(data.createActivity!) },
              ]

              return { ...existingActivities, edges }
            },
          },
        })
      }
    },
  })

  const [updateActivity, { loading: updatingActivity }] = useUpdateActivityMutation({
    onCompleted: (data) => {
      toast.success(`Activity ${data.updateActivity?.id} was updated with success.`)
      onCloseDialog()
    },
  })

  useEffect(() => {
    if (data) {
      reset({ ...data.activity })
    }
  }, [data, reset])

  const mode = id ? Mode.Update : Mode.Create
  const loadingActivity = creatingActivity || updatingActivity

  // handlers
  const handleSubmitActivity: SubmitHandler<CreateUpdateActivityDtoInput> = (formData) => {
    if (mode === Mode.Create) {
      createActivity({
        variables: {
          input: {
            ...formData,
            bookingId: bookingId ? Number(bookingId) : undefined,
          },
        },
      })
    } else {
      updateActivity({
        variables: {
          input: {
            ...formData,
            id,
            bookingId: bookingId ? Number(bookingId) : undefined,
          },
        },
      })
    }
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth='sm'
      aria-labelledby='create-update-activity-dialog-title'>
      <FplDialogTitle id='create-update-activity-dialog-title' onClose={handleCloseDialog}>
        <Typography variant='h4'>Log Activity</Typography>
      </FplDialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <LoadingBackdrop loading={loading} />
          <ActivityForm />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='grey' onClick={handleCloseDialog}>
          Cancel
        </Button>

        <LoadingButton
          variant='contained'
          loading={loadingActivity}
          onClick={handleSubmit(handleSubmitActivity)}
          disabled={!isValid || loadingActivity}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateActivity
