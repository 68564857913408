import { ISelectorOptions } from 'components/common'
import { DriverLicenseType, DriverType, PayFrequency } from 'generated/graphql'

export const Driver = {
  MAX_CALL_SIGN_LENGTH: 20,
  MAX_NATIONAL_INSURANCE_NUMBER_LENGTH: 10,
  MAX_BANK_NAME_LENGTH: 50,
  MAX_BANK_ACCOUNT_NUMBER_LENGTH: 8,
  MAX_CHEQUE_NAME_LENGTH: 50,
  MAX_CREDIT_CARD_LENGTH: 19,
  MAX_EMPLOYEE_REFERENCE_LENGTH: 20,
  MAX_BANK_ACCOUNT_HOLDER_NAME_LENGTH: 200,
  MAX_BANK_INTERNATIONAL_ACCOUNT_NUMBER_LENGTH: 34,
  MAX_NOTE_LENGTH: 2000,
}

export const DriverPurchaseCodes = {
  vatRegisteredPurchaseCode: '5110',
  nonVatRegisteredPurchaseCode: '51101',
}

export const DriverTypeValues: ISelectorOptions = [
  { value: DriverType.Contract, label: 'Contract' },
  { value: DriverType.Cx, label: 'CX' },
  { value: DriverType.Staff, label: 'Staff' },
  { value: DriverType.Other, label: 'Other' },
]

export const PayFrequencyValues: ISelectorOptions = [
  { value: PayFrequency.Monthly, label: 'Monthly' },
  { value: PayFrequency.Weekly, label: 'Weekly' },
]

export const LicenseTypeValues: ISelectorOptions = [
  { value: DriverLicenseType.Hgv1, label: 'Hgv-1' },
  { value: DriverLicenseType.Hgv2, label: 'Hgv-2' },
  { value: DriverLicenseType.Hgv3, label: 'Hgv-3' },
  { value: DriverLicenseType.Motorbike, label: 'Motorbike' },
  { value: DriverLicenseType.Standard, label: 'Standard' },
]
