import { useCallback, useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, GridEventListener, GridRowId } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DEBOUNCE_DELAY, Paths } from 'constants/index'
import {
  SortEnumType,
  StrategicPartnerFilterInput,
  useDeleteStrategicPartnerMutation,
} from 'generated/graphql'
import { GET_STRATEGIC_PARTNERS } from 'graphql/queries/StrategicPartnerQueries'
import { usePermission } from 'providers'

const StrategicPartners = () => {
  const history = useHistory()

  const { control, watch, setValue } = useForm({
    defaultValues: { search: '' },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''

  const [isAllowedToDeleteStrategicPartner] = usePermission('DeleteStrategicPartner')

  const [deleteStrategicPartner, { loading: deletingStrategicPartner }] =
    useDeleteStrategicPartnerMutation({
      refetchQueries: [
        {
          query: GET_STRATEGIC_PARTNERS,
          variables: {
            after: null,
            before: null,
            first: 10,
            last: null,
            order: { name: SortEnumType.Asc },
          },
        },
      ],
    })

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    return history.push(Paths.strategicPartners.updateWithId(id))
  }

  const handleCreateStrategicPartner = () => {
    history.push(Paths.strategicPartners.create)
  }

  // ToDo: add confirmation dialog
  const handleDeleteStrategicPartner = useCallback(
    (id: GridRowId) => () => {
      deleteStrategicPartner({
        variables: {
          strategicPartnerId: Number(id),
        },
      }).then((response) => {
        if (response.data) {
          toast.success(`Strategic Partner ${id} was deleted with success!`)
        }
      })
    },
    [deleteStrategicPartner],
  )

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const columns: IGridColumn[] = useMemo(
    () => [
      { field: 'name', headerName: 'Name', minWidth: 200, flex: 0.5 },
      { field: 'city', headerName: 'City', minWidth: 200, flex: 0.5 },
      {
        field: 'addressLine1',
        headerName: 'Address',
        minWidth: 200,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => {
          if (row.addressLine2) {
            return `${row.addressLine1}, ${row.addressLine2}`
          } else {
            return row.addressLine1
          }
        },
      },
      { field: 'postcode', headerName: 'Postcode', minWidth: 200 },
      { field: 'telephoneNumber', headerName: 'Tel No', minWidth: 200 },
      { field: 'email', headerName: 'Email', minWidth: 200 },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 100,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label='Delete'
            size='large'
            disabled={deletingStrategicPartner || !isAllowedToDeleteStrategicPartner}
            icon={
              <Tooltip title={'Delete'} arrow>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={handleDeleteStrategicPartner(id)}
          />,
        ],
      },
    ],
    [deletingStrategicPartner, handleDeleteStrategicPartner, isAllowedToDeleteStrategicPartner],
  )

  const filter: StrategicPartnerFilterInput | null = useMemo(() => {
    if (!searchValue) {
      return null
    } else {
      return {
        or: [
          { name: { contains: searchValue } },
          { addressLine1: { contains: searchValue } },
          { telephoneNumber: { contains: searchValue } },
          { email: { contains: searchValue } },
        ],
      }
    }
  }, [searchValue])

  return (
    <FplDataGrid
      query={GET_STRATEGIC_PARTNERS}
      entityName='strategicPartners'
      columns={columns}
      filter={filter}
      defaultOrder={{ field: 'name', sort: 'asc' }}
      toolbar={{
        caption: 'Strategic Partners',
        leftSide: (
          <Grid item>
            <Button variant='outlined' size='small' onClick={handleCreateStrategicPartner}>
              New Strategic Partner
            </Button>
          </Grid>
        ),
        rightSide: (
          <Grid item>
            <Box width='210px'>
              <ControlledTextField
                control={control}
                name='search'
                label='Search'
                defaultValue=''
                size='small'
                endAdornment={
                  searchInput && (
                    <IconButton size='small' onClick={handleSearchClearClick}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  )
                }
              />
            </Box>
          </Grid>
        ),
      }}
      onRowClick={handleRowClick}
    />
  )
}

export default StrategicPartners
