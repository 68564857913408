import {
  DEFAULT_VAT_CATEGORY_ID,
  LicenseTypeValues,
  NON_VAT_REGISTERED_CATEGORY_ID,
} from 'constants/index'

import { useEffect, useMemo } from 'react'

import { Grid, Skeleton, Tooltip } from '@mui/material'
import {
  ControlledDatePicker,
  ControlledSelector,
  ControlledTextField,
  ISelectorOptions,
} from 'components/common'
import {
  CreateAndUpdateDriverDtoInput,
  useGetDriverByCurrentUserQuery,
  useGetVatCategoriesQuery,
} from 'generated/graphql'
import { useFormContext, useWatch } from 'react-hook-form'

const GeneralInformationStep = () => {
  const spacing = 2

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateAndUpdateDriverDtoInput>()

  const selectedVatCategoryId = useWatch({
    control,
    name: 'vatCategoryId',
  })

  const { data: vatCategoriesData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const { data: driverByCurrentUserData } = useGetDriverByCurrentUserQuery()

  const vatCategoryItems: ISelectorOptions = useMemo(() => {
    if (!vatCategoriesLoading && vatCategoriesData?.vatCategories) {
      return vatCategoriesData.vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
    }

    return []
  }, [vatCategoriesData, vatCategoriesLoading])

  useEffect(() => {
    if (!vatCategoriesLoading && vatCategoriesData?.vatCategories) {
      const defaultVatCategory = vatCategoriesData.vatCategories.find(
        (vc) => vc.id === NON_VAT_REGISTERED_CATEGORY_ID,
      )
      if (
        driverByCurrentUserData?.driverByCurrentUser?.vatCategoryId === null &&
        defaultVatCategory
      ) {
        setValue('vatCategoryId', defaultVatCategory.id)
      }
    }
  }, [
    vatCategoriesData,
    vatCategoriesLoading,
    setValue,
    driverByCurrentUserData?.driverByCurrentUser?.vatCategoryId,
  ])

  return (
    <Grid container spacing={spacing} justifyContent='center'>
      <Grid container spacing={spacing} item xs={12} sm={6}>
        <Grid item xs={12} sm={5}>
          <ControlledTextField
            control={control}
            label='Company name or Trading name'
            name='name'
            required
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <ControlledTextField
            control={control}
            name='vatIdNumber'
            label='VAT Reg. No'
            error={!!errors.vatIdNumber}
            helperText={errors.vatIdNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={3.5}>
          {vatCategoriesLoading ? (
            <Skeleton variant='rounded' height={22} animation='wave' />
          ) : (
            <>
              {selectedVatCategoryId === NON_VAT_REGISTERED_CATEGORY_ID ? (
                <Tooltip
                  title={'If you are not VAT-registered, there\u2019s no need to change this.'}
                  arrow
                  placement='top'>
                  <ControlledSelector
                    control={control}
                    name='vatCategoryId'
                    label='VAT Category'
                    options={vatCategoryItems}
                    required
                    error={!!errors.vatCategoryId}
                    helperText={errors.vatCategoryId?.message}
                  />
                </Tooltip>
              ) : (
                <ControlledSelector
                  control={control}
                  name='vatCategoryId'
                  label='VAT Category'
                  options={vatCategoryItems}
                  required
                  error={!!errors.vatCategoryId}
                  helperText={errors.vatCategoryId?.message}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            control={control}
            name='eoriNumber'
            label='EORI Number'
            error={!!errors.eoriNumber}
            helperText={errors.eoriNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledDatePicker
            control={control}
            name='startDate'
            label='Start Date'
            required
            error={!!errors.startDate}
            helperText={errors.startDate?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            control={control}
            name='email'
            label='Email'
            type='email'
            required
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            control={control}
            name='invoiceEmail'
            label='Invoice Email'
            type='email'
            error={!!errors.invoiceEmail}
            helperText={errors.invoiceEmail?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledSelector
            control={control}
            options={LicenseTypeValues}
            label='License Type'
            name='licenseType'
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledDatePicker control={control} label='Expiry Date' name='licenseExpiryDate' />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label='Endorsements'
            name='endorsements'
            multiline
            rows={5}
            error={!!errors.endorsements}
            helperText={errors.endorsements?.message}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GeneralInformationStep
