import { Box, Grid } from '@mui/material'

import InvoicePanel from './invoicePanel/InvoicePanel'
import MissingPaymentFieldsPanel from './MissingPaymentFieldsPanel'
import OrderDetailsPanel from './orderDetailsPanel/OrderDetailsPanel'
import PodPanel from './podPanel/podPanel'
import QueryStatusSwitcher from './queryStatus/QueryStatusSwitcher'
import StatusMessagePanel from './StatusMessagePanel'

const AccountPayableDocumentsJobDetailsTab = () => {
  return (
    <Box>
      <Grid item xs={12}>
        <QueryStatusSwitcher />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatusMessagePanel />
        </Grid>
        <Grid item xs={12}>
          <MissingPaymentFieldsPanel />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item md={6} xl={4}>
          <OrderDetailsPanel />
        </Grid>
        <Grid item md={6} xl={4}>
          <InvoicePanel />
        </Grid>
        <Grid item md={6} xl={4}>
          <PodPanel />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountPayableDocumentsJobDetailsTab
