import { useMemo } from 'react'

import { Grid, Paper } from '@mui/material'

import { BookingStatus, GetBookingQuery, GetUserBookingQuery } from 'generated/graphql'
import { Restricted } from 'providers/PermissionProvider'

import { AddressDetails } from './AddressDetails'
import { BookingFooter } from './BookingFooter'
import CourierExchangeDetails from './CourierExchangeDetails'
import { DriverDetails } from './DriverDetails'
import FeeAndCharges from './FeeAndCharges'
import { MainBookingDetails } from './MainBookingDetails'
import NotesAndInstructions from './NotesAndInstructions'
import { OtherCharges } from './OtherCharges'
import { OtherDetails, OtherDetailsFallback } from './OtherDetails'
import { PackageDetails } from './PackageDetails'
import { TariffDetails } from './TariffDetails'

interface IProps {
  bookingId?: string
  booking?: GetBookingQuery['booking'] | GetUserBookingQuery['userBooking']
  isQuote: boolean
  isQuickQuote: boolean
  isUpdate?: boolean
  mode: number | undefined
}

const BookingFormControls = (props: IProps) => {
  const { bookingId, booking, isQuote, isQuickQuote, isUpdate, mode } = props

  const hoverText = useMemo(() => {
    if (!bookingId) {
      return 'Available only for existing booking'
    }

    if (booking?.isOnHold) {
      return 'Booking is On Hold'
    }

    if (booking?.isArchived) {
      return 'Booking is Archived'
    }

    if (booking?.isCancelledOrQuoteLost) {
      return 'Booking is Canceled or Quote Lost'
    }

    if (booking?.status === BookingStatus.ProofOfDelivery) {
      return 'Booking is delivered'
    }

    if (booking?.status === BookingStatus.Complete) {
      return 'Booking is completed'
    }

    if (booking?.status === BookingStatus.Invoice) {
      return 'Booking is moved to Invoice'
    }

    return ''
  }, [booking, bookingId])

  const isBasicDisabled = useMemo(
    () =>
      booking?.isOnHold ||
      booking?.isArchived ||
      booking?.isCancelledOrQuoteLost ||
      booking?.status === BookingStatus.Invoice,
    [booking],
  )

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <MainBookingDetails
            bookingId={bookingId}
            isQuote={isQuote}
            isQuickQuote={isQuickQuote}
            disabled={isBasicDisabled}
            hoverText={hoverText}
            mode={mode}
          />
        </Paper>
      </Grid>

      <AddressDetails
        isQuote={isQuote}
        isQuickQuote={isQuickQuote}
        isUpdate={isUpdate}
        hoverText={hoverText}
        disabled={isBasicDisabled}
      />

      <Grid item xs={12} lg={7} xl={5}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <PackageDetails
            isQuote={isQuote}
            isQuickQuote={isQuickQuote}
            hoverText={hoverText}
            disabled={isBasicDisabled}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} lg={5} xl={3}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <TariffDetails
            bookingId={bookingId}
            isQuote={isQuote}
            hoverText={hoverText}
            disabled={isBasicDisabled}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <OtherCharges hoverText={hoverText} disabled={isBasicDisabled} />
        </Paper>
      </Grid>

      {!isQuote && (
        <Restricted to='ViewAllocatedDrivers'>
          <Grid item xs={12} lg={6} xl={4}>
            <Paper sx={{ padding: 2 }} variant='outlined'>
              <DriverDetails
                bookingId={bookingId}
                booking={booking as GetBookingQuery['booking']}
                disabled={
                  !bookingId ||
                  isBasicDisabled ||
                  booking?.status === BookingStatus.ProofOfDelivery ||
                  booking?.status === BookingStatus.Complete
                }
                hoverText={hoverText}
              />
            </Paper>
          </Grid>
        </Restricted>
      )}

      <Restricted to={['ViewBookingNoteOrSpecialInstruction', 'ViewBookingCourierExchangeDetails']}>
        <Grid item xs={12} lg={5} xl={3}>
          <Grid container spacing={1.5}>
            <Restricted to='ViewBookingNoteOrSpecialInstruction'>
              <Grid item xs={12} sm={6} lg={12}>
                <Paper sx={{ padding: 2 }} variant='outlined'>
                  <NotesAndInstructions
                    booking={booking}
                    hoverText={hoverText}
                    disabled={isBasicDisabled}
                  />
                </Paper>
              </Grid>
            </Restricted>

            <Restricted to='ViewBookingCourierExchangeDetails'>
              <Grid item xs={12} sm={6} lg={12}>
                <Paper sx={{ padding: 2 }} variant='outlined'>
                  <CourierExchangeDetails
                    loadId={booking?.courierExchangeLoadId}
                    lastManualUpdateAt={booking?.courierExchangeLastManualUpdateAt}
                    hoverText={hoverText}
                    disabled={isBasicDisabled}
                  />
                </Paper>
              </Grid>
            </Restricted>
          </Grid>
        </Grid>
      </Restricted>

      <Grid item xs={12} sm={6} lg={3.5} xl={2.5}>
        <Paper sx={{ padding: 2 }} variant='outlined'>
          <FeeAndCharges disabled={isBasicDisabled} hoverText={hoverText} />
        </Paper>
      </Grid>

      <Restricted to='ViewBookingOtherDetails' fallback={<OtherDetailsFallback />}>
        <Grid item xs={12} sm={6} lg={3.5} xl={2.5}>
          <Paper sx={{ padding: 2 }} variant='outlined'>
            <OtherDetails
              isShareWithOurNetwork={isQuote || isQuickQuote ? false : true}
              booking={booking}
              disabled={isBasicDisabled}
              hoverText={hoverText}
            />
          </Paper>
        </Grid>
      </Restricted>

      <Grid item xs={12}>
        <BookingFooter booking={booking} isQuote={isQuote} disabled={isBasicDisabled} />
      </Grid>
    </Grid>
  )
}

export default BookingFormControls
