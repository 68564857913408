import { useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

import { ControlledTextField, FplDataGrid, IGridColumn } from 'components/common'
import { DATE_FORMAT, DEBOUNCE_DELAY } from 'constants/index'
import { QuoteLostReasonFilterInput } from 'generated/graphql'
import { GET_PAGED_QUOTE_LOST_REASONS } from 'graphql/queries/BookingQueries'

import { QuoteLostReasonDialog } from './QuoteLostReasonDialog'

const columns: IGridColumn[] = [
  { field: 'name', headerName: 'Name', minWidth: 200, flex: 0.5 },
  { field: 'code', headerName: 'Code', minWidth: 200, flex: 0.5 },
  { field: 'createdBy', headerName: 'Created By', minWidth: 200, flex: 0.5 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 200,
    flex: 0.5,
    valueFormatter: ({ value }) => value && format(parseJSON(value as string), DATE_FORMAT),
  },
]

const QuoteLostReasons = () => {
  const [selectedQuoteLostReasonId, setSelectedQuoteLostReasonId] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue: string = searchInput.length >= 3 ? searchInput : ''

  const handleRowClick: GridEventListener<'rowClick'> = ({ id }) => {
    setSelectedQuoteLostReasonId(Number(id))
    setOpenDialog(true)
  }

  const handleAddQuoteLostReason = () => {
    setSelectedQuoteLostReasonId(null)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const filter: QuoteLostReasonFilterInput = useMemo(() => {
    return {
      or: [{ name: { contains: searchValue } }],
    }
  }, [searchValue])

  return (
    <>
      <FplDataGrid
        query={GET_PAGED_QUOTE_LOST_REASONS}
        entityName='pagedQuoteLostReasons'
        columns={columns}
        filter={filter}
        defaultOrder={{ field: 'name', sort: 'asc' }}
        toolbar={{
          caption: 'Quote Lost Reasons',
          leftSide: (
            <Grid item>
              <Button variant='outlined' size='small' onClick={handleAddQuoteLostReason}>
                New Quote Lost Reason
              </Button>
            </Grid>
          ),
          rightSide: (
            <Grid item>
              <Box width='210px'>
                <ControlledTextField
                  control={control}
                  name='search'
                  label='Search'
                  defaultValue=''
                  size='small'
                  endAdornment={
                    searchInput && (
                      <IconButton size='small' onClick={handleSearchClearClick}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </Grid>
          ),
        }}
        onRowClick={handleRowClick}
      />

      {openDialog && (
        <QuoteLostReasonDialog
          id={selectedQuoteLostReasonId}
          openDialog={openDialog}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </>
  )
}

export default QuoteLostReasons
